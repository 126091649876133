import React from "react";
import { Flex, Grid, Text } from "@aws-amplify/ui-react";
import cs1 from "./assets/cs1.png"
import TxtButton from "./ui_components/TxtButton";
import "./ui_components/component.css"

export default function Questions({
  onNextChange,
  onBackChange,
  Response,
  onAnswer,
}: any) {
  // initial values
  let answer = {
    one: "",
    two: "",
    three: "",
    four: ""
  };
  let type = "bottom"
  let question = "";

  // updates with the Response and Type
  if (Response === "Q1") {
    question = "How many employees do you have?"
    answer = {
      one: "< 10",
      two: "11 - 20",
      three: "21 - 50",
      four: "> 50"
    };
  }
  if (Response === "Q2") {
    question = "What is your annual revenue?"
    answer = {
      one: "<$500k",
      two: "$500k - $1M",
      three: "$1M - $5M",
      four: "> $5M"
    };
  }
  if (Response === "Q3") {
    question = "Who is handling your IT?"
    answer = {
      one: "We figure it out as we go",
      two: "One of our employee's cousins",
      three: "We have a trained IT person on staff",
      four: "We outsource to an IT company"
    };
  }
  if (Response === "Q4") {
    question = "What is your monthly IT budget?"
    answer = {
      one: "$50/user: I'm trying to pinch pennies",
      two: "$100/user: Squared away with the basics",
      three: "$150/user: On the ball with client data protection",
      four: "$200/user: I need IT to never be a headache"
    };
  }

  return (
    <Flex
      direction={"column"}
      gap="0"
      alignItems={"center"}
      className="page"
      backgroundColor={"#144EE3"}
      height={{base: "100vh", large:"90vh"}}
      width={"100vw"}
      maxWidth={{base: "100vw", large: "600px"}}
      maxHeight={{large: "900px"}}
    >
      <Grid
        height={{base: "85vh", medium: "75vh", large: "75vh"}}
        width={"70vw"}
        templateRows={{
          base: "3fr 2fr 10fr",
          medium: "2fr 1fr 6fr",
          large: "2fr 1fr 6fr",
        }}
        paddingTop={{ base: "20%", medium: "10%", large: "10%" }}
        templateColumns={"auto"}
        // backgroundColor={"lightpink"}
      >
        <img
          src={cs1}
          height="100px"
          width="100px"
          alt="Cyber Score Image"
          style={{
            borderRadius: "50%",
            boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
            gridRow: "1",
            justifySelf: "center",
          }}
        />
        <Flex justifyContent={"center"}>
          <Text
            color={"white"}
            textAlign={"center"}
            // fontSize={"large"}
            fontWeight={"bold"}
            className="headers"
          >
            {question}
          </Text>
        </Flex>
        <Flex
          justifyContent={"center"}

        >
          <Flex
            direction={"column"}
            alignItems={"center"}
            gap="20px"
            width={"100%"}
          >
            <TxtButton
              setter={() => onAnswer(answer.one)}
              txt={answer.one}
              type={"question"}
            />
            <TxtButton
              setter={() => onAnswer(answer.two)}
              txt={answer.two}
              type={"question"}
            />
            <TxtButton
              setter={() => onAnswer(answer.three)}
              txt={answer.three}
              type={"question"}
            />
            <TxtButton
              setter={() => onAnswer(answer.four)}
              txt={answer.four}
              type={"question"}
            />
          </Flex>
        </Flex>
      </Grid>
      <Grid
        height={"15vh"}
        width={"100vw"}
        backgroundColor={"white"}
        justifyContent={"center"}
      >
        <Grid
          width={"100%"}
          templateColumns={"5fr 5fr"}
        >
          <TxtButton
            setter={() => onBackChange(true)}
            txt={"Back"}
            type={type}
          />
          <TxtButton
            setter={() => onNextChange(true)}
            txt={"Next"}
            type={"Placeholder"}
          />
        </Grid>
      </Grid>
    </Flex>
  );
}
