import React, { createContext, ReactNode, useContext } from "react";
import { Modal } from "../../../components/Modal";
import { Flex, TextField, Button, Link, Text } from "@aws-amplify/ui-react";
import { useBranding } from "../../branding/hooks";
import posthog from "posthog-js";
import { useParams } from "react-router-dom";
import { useViewAccess } from "../hooks";
import { mailchimpSubscribe } from "../../marketing";
import { useMutation } from "@tanstack/react-query";

type CallToActionContextState = {
  openModal: (source?: string) => void;
};

// Create the context
export const CallToActionContext = createContext<
  CallToActionContextState | undefined
>(undefined);

export const useCallToActionContext = () => {
  const context = useContext(CallToActionContext);
  if (context === undefined) {
    throw new Error(
      "useCallToActionContext must be used within a CallToActionProvider"
    );
  }
  return context;
};

// Create a provider component
export const CallToActionProvider = ({ children }: { children: ReactNode }) => {
  const ctaModalRef = React.useRef<HTMLDialogElement>(null);
  const { unlockDetails } = useViewAccess();
  const { branding, brandingId } = useBranding();
  const { domain } = useParams();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const { mutateAsync: submitForm, status } = useMutation({
    mutationKey: ["lead-form-submit", email, name, domain, brandingId],
    mutationFn: async () => {
      posthog.identify(email, {
        name: name,
        mspId: brandingId,
      });

      const firstName = name.split(" ")[0];
      const lastName = name.split(" ").splice(1).join(" ");
      await mailchimpSubscribe({
        email,
        firstName,
        lastName,
        companySite: domain ?? "",
        msp: branding.mspName ?? "The Cyber Score",
      });
    },
    onSuccess: () => {
      unlockDetails();
      ctaModalRef.current?.close();
    },
  });

  return (
    <CallToActionContext.Provider
      value={{
        openModal: (source) => {
          ctaModalRef.current?.showModal();
          posthog.capture("lead-capture-modal-opened", {
            mspId: brandingId,
            domain: domain,
            source: source,
          });
        },
      }}
    >
      <Modal
        ref={ctaModalRef}
        size="small"
        open={true}
        header="💯 Unlock your full Score Card"
      >
        <Flex
          as="form"
          direction={"column"}
          width={"100%"}
          data-attr="submit-lead-gen-form"
          data-ph-capture-attribute-name={name}
          data-ph-capture-attribute-email={email}
          data-ph-capture-attribute-mspid={brandingId}
          data-ph-capture-attribute-domain={domain}
          data-ph-capture-attribute-mspname={branding.mspName}
          data-ph-capture-attribute-contactemail={branding.contactEmail}
          data-ph-capture-attribute-contactname={branding.contactName}
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <TextField
            isRequired
            label={"Name:"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          ></TextField>
          <TextField
            isRequired
            label={"Email:"}
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></TextField>
          <Button
            backgroundColor={branding.accentColor}
            type="submit"
            color={"white"}
            isLoading={status === "pending"}
            style={{
              textShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
            }}
          >
            Submit
          </Button>
          <Text fontSize={"small"} alignSelf={"center"} color={"font.tertiary"}>
            By clicking submit you agree to Iceberg Cyber's{" "}
            <Link isExternal href="https://www.icebergcyber.com/terms">
              Terms & Conditions
            </Link>
            , agree to receiving emails from us, and consent to our use of
            cookies. Please read our{" "}
            <Link isExternal href="https://www.icebergcyber.com/privacy">
              Privacy Policy
            </Link>
            .
          </Text>
        </Flex>
      </Modal>
      {children}
    </CallToActionContext.Provider>
  );
};
