// import { fetchAuthSession } from "aws-amplify/auth";
// import { getRequest } from "./restAPI";
// // import { Auth } from "aws-amplify";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";


export const invokeLambda = async (funcName: string, payload: any) => {
  try {

    const client = new LambdaClient({
      region: "us-east-2",
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: "us-east-2" }, 
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID as string, 
      }),
    });
    
    const command = new InvokeCommand({
      FunctionName: funcName,
      Payload: JSON.stringify(payload),
    });

    const { Payload } = await client.send(command);
    //convert Payload from UInt8ArrayBlobAdapter to string
    const decoder = new TextDecoder("utf-8");
    const decodedPayload = decoder.decode(Payload);
    const parsedPayload = JSON.parse(decodedPayload);
    return parsedPayload;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
