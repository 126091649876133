import {
  Button,
  Card,
  Flex,
  Heading,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Text,
  Link,
} from "@aws-amplify/ui-react";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { ScoreClaim } from "../types";
import { post } from "../../../services/aws-amplify";
import { useBranding } from "../../branding/hooks";

type ClaimScoreViewProps = {
  domain: string | undefined;
};

export function ClaimScoreView({ domain }: ClaimScoreViewProps) {
  type ClaimForm = {
    name: string;
    email: string;
    answers: (-1 | 1)[];
  };

  const { branding } = useBranding();

  const questions = [
    {
      title: "Multi-Factor Authentication",
      text: "Do you use Multi-Factor Authentication for all employee email accounts?",
    },
    {
      title: "Anti-Virus",
      text: "Do you have anti-virus software installed on all employee computers?",
    },
    {
      title: "Cyber Awareness Training",
      text: "Do you train your employees with cyber awareness skills on at least a quarterly basis?",
    },
    {
      title: "Cyber Insurance",
      text: "Do you have an insurance policy covering the damages from cyber attacks?",
    },
  ];
  const [claimForm, setBonusForm] = useState<ClaimForm>({
    name: "",
    email: "",
    answers: Array(questions.length).fill(-1),
  });

  const [menu, setMenu] = useState<"claim" | "finished">("claim");

  const { name, email, answers } = claimForm;
  const { mutateAsync: createScoreClaim, isPending: pendingScoreClaim } =
    useMutation({
      mutationKey: ["submit-claim"],
      mutationFn: async (data: ClaimForm) => {
        if (!domain) throw new Error("Domain is required");

        const { name, email, answers } = data;

        const [mfaAnswer, antiVirusAnswer, trainingAnswer, insuredAnswer] =
          answers;

        const scoreClaim: ScoreClaim = {
          domain,
          status: "pending",
          name,
          email,
          mfaAnswer,
          antiVirusAnswer,
          trainingAnswer,
          insuredAnswer,
          resolvedBreaches: -1,
          mspId: branding.id
        };

        await post("ScoreClaimAPI", "/score-claim", {
          body: scoreClaim,
        });
      },
      onSuccess() {
        setMenu("finished");
      },
    });
  switch (menu) {
    case "claim":
      return (
        <Flex
          direction={"column"}
          as="form"
          gap={"small"}
          onSubmit={(e) => {
            e.preventDefault();
            createScoreClaim(claimForm);
          }}
        >
          <Heading level={5}>
            Tell us about your company to instantly improve your score.
          </Heading>
          <Flex direction={{ base: "column", large: "row" }} gap={"xs"}>
            <TextField
              flex={1}
              label="Name"
              value={name}
              onChange={(e) =>
                setBonusForm({ ...claimForm, name: e.target.value })
              }
            ></TextField>

            <TextField
              flex={2}
              label="Email"
              value={email}
              onChange={(e) =>
                setBonusForm({ ...claimForm, email: e.target.value })
              }
            ></TextField>
          </Flex>
          <Flex direction={"column"} gap={"xs"}>
            {questions.map((question, i) => (
              <Card
                backgroundColor={"background.tertiary"}
                borderRadius={"medium"}
              >
                <Flex alignItems={"center"}>
                  <Flex direction={"column"} gap={"xxs"} flex={1}>
                    <Flex flex={1} alignItems={"center"}>
                      <Heading flex={1}>{question.title}</Heading>{" "}
                      <ToggleButtonGroup
                        isExclusive
                        value={answers[i] === 1 ? "yes" : "no"}
                        onChange={(value) => {
                          if (!value) return;
                          const newAnswers = [...answers];
                          const v = value as string;
                          newAnswers[i] = v === "yes" ? 1 : -1;
                          setBonusForm({ ...claimForm, answers: newAnswers });
                        }}
                      >
                        <ToggleButton className="yes" value="yes">
                          Yes
                        </ToggleButton>
                        <ToggleButton className="no" value="no">
                          No
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Flex>
                    <Text>{question.text}</Text>
                  </Flex>
                </Flex>
              </Card>
            ))}
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Text
              fontSize={"small"}
              alignSelf={"center"}
              color={"font.tertiary"}
            >
              By pressing submit you agree to Iceberg Cyber's{" "}
              <Link isExternal href="https://www.icebergcyber.com/terms">
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link isExternal href="https://www.icebergcyber.com/privacy">
                Privacy Policy
              </Link>
            </Text>
            <Button
              type="submit"
              alignSelf={"end"}
              variation={"primary"}
              backgroundColor={branding?.accentColor || "#144EE3"}
              isLoading={pendingScoreClaim}
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      );
    case "finished":
      return (
        <Flex direction={"column"} gap={"xl"}>
          <Heading level={5}>
            Success! We will review your submission and update your Score. Check
            back soon.
          </Heading>
          <Text>
            Your score will be updated shortly. You can close this window.
          </Text>
        </Flex>
      );
  }
}
