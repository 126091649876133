import React, { useState } from "react";
import { Flex, Grid, Text, Autocomplete } from "@aws-amplify/ui-react";
import cs1 from "./assets/cs1.png";
import TxtButton from "./ui_components/TxtButton";

export default function Dropdown({
  onNextChange,
  onBackChange,
  onAnswer,
}: any) {
  let type = "bottom";
  let question = "What industry are you in?";
  const [value, setValue] = useState("");

  const onChange = (event: any) => {
    setValue(event.target.value);
  };

  const onSelect = (option: any) => {
    const { label } = option;
    setValue(label);
  };

  const onClear = () => {
    setValue("");
  };

  function onClick() {
    onAnswer(value);
    onNextChange(true);
  }

  const industries = [
    { id: "automotive", label: "Automotive" },
    { id: "technology", label: "Technology" },
    { id: "finance", label: "Finance" },
    { id: "healthcare", label: "Healthcare" },
    { id: "retail", label: "Retail" },
    { id: "education", label: "Education" },
    { id: "entertainment", label: "Entertainment" },
    { id: "hospitality", label: "Hospitality" },
    { id: "manufacturing", label: "Manufacturing" },
  ];

  const style = {
    backgroundColor: "white",
    border: "1px solid white",
    borderRadius: "8px",
    boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
    justifyContent: "flex-start",
  };

  return (
    <Flex
      direction={"column"}
      gap="0"
      alignItems={"center"}
      className="page"
      backgroundColor={"#144EE3"}
      height={{ base: "100vh", large: "90vh" }}
      width={"100vw"}
      maxWidth={{base: "100vw", large: "600px"}}
      maxHeight={{large: "900px"}}
    >
      <Grid
        height={{ base: "85vh", medium: "75vh", large: "75vh" }}
        width={"85vw"}
        templateRows={{
          base:"3fr 1fr 9fr",
          medium: "2fr 1fr 6fr",
          large: "2fr 1fr 6fr",
        }}
        paddingTop={{ base: "20%", medium: "10%", large: "10%" }}
        // backgroundColor={"lightpink"}
      >
        <img
          src={cs1}
          height="100px"
          width="100px"
          alt="Cyber Score Image"
          style={{
            borderRadius: "50%",
            boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
            gridRow: "1",
            justifySelf: "center",
          }}
        />
        <Flex justifyContent={"center"}>
          <Text
            color={"white"}
            textAlign={"center"}
            // fontSize={"large"}
            fontWeight={"bold"}
            className="headers"
          >
            {question}
          </Text>
        </Flex>
        <Flex
          justifyContent={"center"}
        >
          <Flex
            direction={"column"}
            gap="30px"
          >
            <Autocomplete
              label=""
              width={"40vw"}
              minWidth={"300px"}
              maxWidth={"450px"}
              marginTop={{base: "6px", large:"12px"}}
              border= "2px solid white"
              borderRadius= "8px"
              backgroundColor= "white"
              options={industries}
              placeholder="..."
              style={style}
              size="large"
              fontSize={"28px"}
              value={value}
              onChange={onChange}
              onClear={onClear}
              onSelect={onSelect}
            />
          </Flex>
        </Flex>
      </Grid>
      <Grid
        height={"15vh"}
        width={"100vw"}
        backgroundColor={"white"}
        justifyContent={"center"}
      >
        <Grid
          width={ "100%"}
          templateColumns={"5fr 5fr"}
        >
          <TxtButton
            setter={() => onBackChange(true)}
            txt={"Back"}
            type={type}
          />
          <TxtButton
            setter={() => onClick()}
            txt={"Next"}
            type={type}
          />
        </Grid>
      </Grid>
    </Flex>
  );
}
