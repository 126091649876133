import React, { useState, useEffect } from "react";
import { Text, Flex, Grid } from "@aws-amplify/ui-react";
// import MyAnimationComponent from "./animations/TxtAnimationMap";
import TxtButton from "./ui_components/TxtButton";
import cs1 from "./assets/cs1.png";
import "./ui_components/component.css"
import image from "./animations/shield_small.gif"
import usePeterGenerator from "../../hooks/usePeterGenerator";

export default function Loader({ onNextChange, onBackChange }: any) {
  // const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { isLoading } = usePeterGenerator({ responseType: "Full" });



  // checking out the window size:
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const mobileBreakpoint = 480;

  const isLargeScreen = windowWidth >= mobileBreakpoint;

  // Handle the loading state with a timeout
  useEffect(() => {
    const timer = setTimeout(() => {
      // setLoading(false);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }, []);

  return (
    <Flex
      direction={"column"}
      gap="0"
      alignItems={"center"}
      className="page"
      backgroundColor={"#144EE3"}
      height={{ base: "100vh", large: "90vh" }}
      width={"100vw"}
      maxWidth={{base: "100vw", large: "600px"}}
      maxHeight={{large: "900px"}}
    >
      <Grid
        height={{base: "85vh", medium: "75vh", large: "75vh"}}
        width={"70vw"}
        maxWidth={"500px"}
        templateRows={{
          base: "3fr 1fr 9fr",
          medium: "2fr 1fr 6fr",
          large: "2fr 1fr 6fr",
        }}
        paddingTop={{ base: "20%", medium: "10%", large: "10%" }}
      >
        <img
          src={cs1}
          height="100px"
          width="100px"
          alt="Cyber Score Image"
          style={{
            borderRadius: "50%",
            boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
            gridRow: "1",
            justifySelf: "center",
          }}
        />
        <Flex justifyContent={"center"}>
          <Text
            color={"white"}
            textAlign={"center"}
            // fontSize={"large"}
            fontWeight={"bold"}
            className="headers"
          >
            Crafting your Cyber Roadmap...
          </Text>
        </Flex>
        <Flex
          direction={"column"}
          gap="20px"
          paddingTop={"2%"}
          alignItems={"center"}
          onClick={() => onNextChange(true)}
        >
          {/* <MyAnimationComponent /> */}
          {isLargeScreen ? (
            <img src={image} height={"300px"} alt="" />
          ) : (
            <img src={image} height={"200px"} alt="" />
          )}
        </Flex>
      </Grid>
      <Grid
        height={"15vh"}
        width={"100vw"}
        backgroundColor={"white"}
        justifyContent={"center"}
      >
        <Grid
          width={"100%"}
          templateColumns={"5fr 5fr"}
        >
          <TxtButton
            setter={() => onBackChange(true)}
            txt={"Back"}
            type={"bottom"}
          />
          {isLoading ? (
            <TxtButton
              setter={() => onNextChange(true)}
              txt={"Next"}
              type={"load"}
            />
          ) : (
            <TxtButton
              setter={() => onNextChange(true)}
              txt={"Next"}
              type={"bottom"}
            />
          )}
        </Grid>
      </Grid>
    </Flex>
  );
}
