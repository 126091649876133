import React, { useState } from "react";
import P4 from "./P4";
import P2 from "./P2";
import Questions from "../Questions";
// import useData from "../useData";
import { useData } from '../DataContext';

export default function P3() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState(false);

  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);

  // New function that runs handleAnswer and sets next to true
  // const onAnswerAndUpdateNext = (answer: any) => {
  //   handleAnswer1(answer);
  //   setNext(true);
  // };

  const { handleAnswer } = useData();

  const onAnswerAndUpdateNext = (answer: any) => {
    handleAnswer('annualRevenue', answer);
    setNext(true);
  };
  return (
    <>
      {back ? (
        <P2 />
      ) : (
        <>
          {next ? (
            <P4 />
          ) : (
            <Questions
              onNextChange={handleNextChange}
              onAnswer={onAnswerAndUpdateNext}
              onBackChange={handleBackChange}
              Response={"Q2"}
            />
          )}
        </>
      )}
    </>
  );
}
