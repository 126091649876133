import React, { Suspense, useEffect } from "react";
import { Routes, Route, useSearchParams, useNavigate } from "react-router-dom";
import ReconViewDomain from "../pages/ReconViewDomain";
import ReconViewCompany from "../pages/ReconViewCompany";
import parse from "tld-extract";
import { Flex, Loader } from "@aws-amplify/ui-react";
import { CallToActionProvider } from "../features/call-to-actions/contexts/CallToActionContext";

export default function ReconRoutes() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const domain =
      searchParams.get("domain")?.split("@")[1] || searchParams.get("domain");

    // If not found in query parameters, extract from the path
    if (domain) {
      try {
        const { domain: extractedDomain } = parse(domain);
        if (extractedDomain) {
          searchParams.delete("domain");
          navigate(`/score-search/${extractedDomain.replace("www.", "")}`);
        }
      } catch {
        navigate(`/score-search/${domain.replace("www.", "")}`);
      }
    }
  });

  return (
    <Suspense
      fallback={
        <Flex
          height={"100dvh"}
          width={"100vw"}
          justifyContent={"center"}
          alignItems={"center"}
          backgroundColor={"neutral.100"}
        >
          <Loader size="large" />
        </Flex>
      }
    >
      <Routes>
        <Route index element={<ReconViewDomain />} />
        <Route
          path="/score-search/:domain/*"
          element={
            <CallToActionProvider>
              <ReconViewCompany />
            </CallToActionProvider>
          }
        />
      </Routes>
    </Suspense>
  );
}
