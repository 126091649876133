import React, { useState } from "react";
import { Flex, Grid, Text, Button, CheckboxField } from "@aws-amplify/ui-react";
import cs1 from "./assets/cs1.png";
import TxtButton from "./ui_components/TxtButton";
import "./checkbox.css";
import "./ui_components/component.css"

interface SelectState {
  one: boolean;
  two: boolean;
  three: boolean;
  four: boolean;
}

export default function Checkbox({
  onNextChange,
  onBackChange,
  onAnswer,
}: any) {
  const [select, setSelect] = useState<SelectState>({
    one: false,
    two: false,
    three: false,
    four: false,
  });

  const value = {
    one: "Use MFA for company bank account",
    two: "Use MFA for company employee email accounts",
    three: "Back up corporate data every month",
    four: "Update Windows/MacOS on employee computers monthly",
  };

  const handleClick = (key: keyof SelectState) => {
    setSelect((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  let type = "bottom";
  let question = "Do you...";

  const baseStyle = {
    cursor: "pointer",
    color: "#144EE3",
    backgroundColor: "white",
    border: "1px solid white",
    borderRadius: "8px",
    boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
    // padding: "10px 10px",
    justifyContent: "flex-start",
  };

  const style =
    type === "clicked"
      ? {
          cursor: "pointer",
          color: "black",
          backgroundColor: "#144EE3",
          border: "1px solid white",
          borderRadius: "8px",
          boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
          padding: "10px 10px",
          margin: "30px",
          maxWidth: "200px",
          maxHeight: "50px",
        }
      : baseStyle;

  function onClick() {
    onAnswer(select);
    onNextChange(true);
  }

  return (
    <div>
      <Flex
        direction={"column"}
        gap="0"
        alignItems={"center"}
        className="page"
        backgroundColor={"#144EE3"}
        height={{ base: "100vh", large: "90vh" }}
        width={"100vw"}
        maxWidth={{ base: "100vw", large: "600px" }}
        maxHeight={{ large: "900px" }}
      >
        <Grid
          height={{ base: "85vh", medium: "75vh", large: "75vh" }}
          width={"80vw"}
          templateRows={{
            base: "3fr 1fr 9fr",
            medium: "2fr 1fr 6fr",
            large: "2fr 0fr 7fr",
          }}
          paddingTop={{ base: "20%", medium: "10%", large: "10%" }}
          templateColumns={"auto"}
        >
          <img
            src={cs1}
            height="100px"
            width="100px"
            alt="Cyber Score Image"
            style={{
              borderRadius: "50%",
              boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
              gridRow: "1",
              justifySelf: "center",
            }}
          />
          <Flex justifyContent={"center"}>
            <Text
              color={"white"}
              textAlign={"center"}
              // fontSize={"large"}
              fontWeight={"bold"}
              className="headers"
            >
              {question}
            </Text>
          </Flex>
          <Flex
            justifyContent={"center"}
            alignItems={"baseline"}
            // paddingLeft={{base:"10%", medium: "5%", large: "5%"}}
          >
            <Flex
              direction={"column"}
              // backgroundColor={"lightblue"}
              gap="10px"
            >
              <Button style={style} className="checkbox">
                <CheckboxField
                  className="checkbox-font"
                  label={value.one}
                  name=""
                  value=""
                  gap={"1em"}
                  size="small"
                  style={{ alignContent: "center" }}
                  onClick={() => handleClick("one")}
                />
              </Button>
              <Button style={style} className="checkbox">
                <CheckboxField
                  className="checkbox-font"
                  label={value.two}
                  name=""
                  value=""
                  gap={"1em"}
                  size="small"
                  style={{ alignContent: "center" }}
                  onClick={() => handleClick("two")}
                />
              </Button>
              <Button style={style} className="checkbox">
                <CheckboxField
                  className="checkbox-font"
                  label={value.three}
                  name=""
                  value=""
                  gap={"1em"}
                  size="small"
                  style={{ alignContent: "center" }}
                  onClick={() => handleClick("three")}
                />
              </Button>
              <Button style={style} className="checkbox">
                <CheckboxField
                  className="checkbox-font"
                  label={value.four}
                  name=""
                  value=""
                  gap={"1em"}
                  size="small"
                  style={{ alignContent: "center" }}
                  onClick={() => handleClick("four")}
                />
              </Button>
            </Flex>
          </Flex>
        </Grid>
        <Grid
          height={"15vh"}
          width={"100vw"}
          backgroundColor={"white"}
          justifyContent={"center"}
        >
          <Grid
            width={"100%"}
            templateColumns={"5fr 5fr"}
          >
            <TxtButton
              setter={() => onBackChange(true)}
              txt={"Back"}
              type={type}
            />
            <TxtButton setter={() => onClick()} txt={"Next"} type={type} />
          </Grid>
        </Grid>
      </Flex>
    </div>
  );
}
