import React, { useState, useEffect } from "react";
import { Flex, Grid, Text } from "@aws-amplify/ui-react";
import TxtButton from "./ui_components/TxtButton";
import cs1 from "./assets/cs1.png";
import TxtResponse from "./ui_components/TxtResponse";
import "./ui_components/component.css";
import { useData } from "../CTA/DataContext";
import target from "./animations/target_small.gif"
import usePeterGenerator from "../../hooks/usePeterGenerator";

export default function PeterResponse({ onNextChange, onBackChange }: any) {
  // const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { data } = useData();
  const { isLoading } = usePeterGenerator({ responseType: "Teaser" });

  // GOAL: set this component to loading if the data.teaser is empty
  // Call the PeterGenerator hook to generate the teaser
  // Teaser needs to be saved to data.teaser somehow, and then displayed
  // If the data.teaser is not empty, then component should no longer be set to loading



  // // Peter Logic
  // usePeterGenerator({ responseType: "Teaser" });

  // checking out the window size:
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  const mobileBreakpoint = 480;

  const isLargeScreen = windowWidth >= mobileBreakpoint;


  return (
    <>
      {isLoading ? (
        <Flex
          direction={"column"}
          gap="0"
          alignItems={"center"}
          className="page"
          backgroundColor={"#144EE3"}
          height={{ base: "100vh", large: "90vh" }}
          width={"100vw"}
          maxWidth={{ base: "100vw", large: "600px" }}
          maxHeight={{ large: "900px" }}
        >
          <Grid
            height={{ base: "85vh", medium: "75vh", large: "75vh" }}
            width={"70vw"}
            maxWidth={"500px"}
            templateRows={{
              base: "3fr 1fr 9fr",
              medium: "2fr 1fr 6fr",
              large: "2fr 1fr 6fr",
            }}
            paddingTop={{ base: "20%", medium: "10%", large: "10%" }}
          >
            <img
              src={cs1}
              height="100px"
              width="100px"
              alt="Cyber Score Image"
              style={{
                borderRadius: "50%",
                boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
                gridRow: "1",
                justifySelf: "center",
              }}
            />
            <Flex justifyContent={"center"}>
              <Text
                color={"white"}
                textAlign={"center"}
                // fontSize={"large"}
                fontWeight={"bold"}
                className="headers"
              >
                Generating some insights...
              </Text>
            </Flex>
            <Flex
              direction={"column"}
              gap="20px"
              paddingTop={"2%"}
              alignItems={"center"}
              onClick={() => onNextChange(true)}
            >
              {isLargeScreen ? (
                <img src={target} height={"300px"} alt="" />
              ) : (
                <img src={target} height={"200px"} alt="" />
              )}
            </Flex>
          </Grid>
          <Grid
            height={"15vh"}
            width={"100vw"}
            backgroundColor={"white"}
            justifyContent={"center"}
          >
            <Grid
              width={{
                base: "100vw",
                medium: "30vw",
                large: "40vw",
                xl: "40vw",
                xxl: "500px",
              }}
              templateColumns={"5fr 5fr"}
              paddingLeft={"15px"}
            >
              <TxtButton
                setter={() => onBackChange(true)}
                txt={"Back"}
                type={"bottom"}
              />
              <TxtButton
                setter={() => onNextChange(true)}
                txt={"Next"}
                type={"Placeholder"}
              />           
            </Grid>
          </Grid>
        </Flex>
      ) : (
        <Flex
          direction={"column"}
          gap="0"
          alignItems={"center"}
          className="page"
          backgroundColor={"#144EE3"}
          height={{ base: "100vh", large: "90vh" }}
          width={"100vw"}
          maxWidth={{ base: "100vw", large: "600px" }}
          maxHeight={{ large: "900px" }}
        >
          <Grid
            height={{ base: "85vh", medium: "75vh", large: "75vh" }}
            width={"80vw"}
            maxWidth={"500px"}
            templateRows={{
              base: "3fr 1fr 9fr",
              medium: "2fr 1fr 6fr",
              large: "2fr 1fr 6fr",
            }}
            templateColumns={"auto"}
            paddingTop={{ base: "20%", medium: "10%", large: "10%" }}
          >
            <img
              src={cs1}
              height="100px"
              width="100px"
              alt="Cyber Score Image"
              style={{
                borderRadius: "50%",
                boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
                gridRow: "1",
                justifySelf: "center",
              }}
            />
            <Flex justifyContent={"center"} direction={"column"}>
              <Text
                color={"white"}
                textAlign={"center"}
                // fontSize={"large"}
                fontWeight={"bold"}
                style={{ gridRow: "2" }}
                className="headers"
              >
                Here's a sneak peek...
              </Text>
            </Flex>
            <Flex
              direction={"column"}
              // backgroundColor={"lightblue"}
              gap="20px"
              alignItems={"center"}
              style={{ gridRow: "3" }}
            >
              <Flex
                direction={"column"}
                gap="20px"
                style={{ gridRow: "3" }}
                marginTop={{ base: "6px", large: "12px" }}
              >
                <TxtResponse txt={data.teaser} />
              </Flex>
            </Flex>
          </Grid>
          <Grid
            height={"15vh"}
            width={"100vw"}
            backgroundColor={"white"}
            justifyContent={"center"}
          >
            <Grid
              width={"100%"}
              templateColumns={"5fr 5fr"}
            >
              <TxtButton
                setter={() => onBackChange(true)}
                txt={"Back"}
                type={"bottom"}
              />
              <TxtButton
                setter={() => onNextChange(true)}
                txt={"Next"}
                type={"bottom"}
              />
            </Grid>
          </Grid>
        </Flex>
      )}
    </>
  );
}
