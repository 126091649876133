import { useSuspenseQuery } from "@tanstack/react-query";
import { getBranding } from "../services";
import logo from "../../../assets/images/pdf/logo.png";
import { useSearchParams } from "react-router-dom";
import { Branding } from "../types";

export function useBranding() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const { data: branding, ...query } = useSuspenseQuery({
    queryKey: ["branding", id],
    queryFn: () =>
      id
        ? getBranding(id)
        : ({
            primaryColor: "#0B101B",
            accentColor: "#144EE3",
            yourLogo: logo,
            clientLogo: "",
            fontColor: "#fff",
            contactName: "Michael Bakaic",
            contactEmail: "michael@thecyberscore.com",
            phoneNumber: "",
            slogan: "Cyber Version of a Credit Score",
            mspName: "theCyberScore",
          } as Branding),
  });

  return {
    brandingId: id,
    branding: branding,
    ...query,
  };
}
