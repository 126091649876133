import React, {useState} from "react";
import P6 from "./P6";
import P8 from "./P8";
import Checkbox from "../Checkbox";
import { useData } from "../DataContext";

export default function P7() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState(false);

  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);

  const { handleAnswer } = useData();

  const onAnswerAndUpdateNext = (answer: any) => {
    handleAnswer("mfaBankAccount", answer.one)
    handleAnswer("mfaEmailAccounts", answer.two)
    handleAnswer("conductingBackups", answer.three)
    handleAnswer("updatingDevices", answer.four)
    setNext(true);
  };

  return (
    <>
      {back ? (
        <P6 />
      ) : (
        <>
          {next ? (
            <P8 />
          ) : (
            <Checkbox
            onNextChange={handleNextChange}
            onBackChange={handleBackChange}
            onAnswer={onAnswerAndUpdateNext}
            />
          )}
        </>
      )}
    </>
  );
}