import cyberCenterDescriptions from "./cyberCenterDescriptions";
import { CyberScoreCombined } from "../services/getCyberScoreCombined";
import { GraphItem } from "../../components/Interfaces";

export default function transformSnakeInput(
  input: CyberScoreCombined
): GraphItem[] {
  let currentValue = 14; //the first triangles value, needs to be constant to fit in area

  // the empty object in which the output will go into
  const items: GraphItem[] = [];

  // use the keys of the simpleAPI to be the "key" key of each object within the array (useful tracing back)
  // other keys include, value, hue, status, color, title
  Object.keys(input).forEach((key) => {
    // loop through each simpleAPI key
    // status logic
    const status =
      input[key] === 1 ? "Pass" : input[key] === -1 ? "Fail" : "N/A";
    // based off status then color is assigned
    const color =
      status === "Pass"
        ? "20, 78, 227"
        : status === "Fail"
        ? "255, 50, 58 "
        : "240, 240, 240";
    // leverage the titles created within description file, if needed we could host within this file too
    const title = cyberCenterDescriptions[key]?.title || "Unknown Title";

    // push this new object into the items array
    items.push({
      key: key,
      value: currentValue,
      status: status,
      color: color,
      title: title,
    });

    // descending value and hue per new entry within fail,pass, na buckets.
    currentValue = Math.max(7, currentValue - 1);
  });

  // Sort the items in descending order based on value to make sure the output will match "data" within the useEffect
  return items.sort((a, b) => {
    if (a.status === "Fail" && b.status !== "Fail") return -1;
    if (b.status === "Fail" && a.status !== "Fail") return 1;
    return b.value - a.value;
  });
}
