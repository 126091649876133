import React from "react";
import { Link } from '@aws-amplify/ui-react';
import CyberCenterContent from "./CyberCenterContent";


export default function WebsiteSecuritySettingsInfo(props: { values: any; cyberScore: any}) {
  const { values, cyberScore } = props;

  return (
    <CyberCenterContent
      whatIsItContent={`Website security settings, like HTTP secure headers, help protect your site and visitors. Content Security Policy (CSP) controls what resources the site can load, reducing risk of malicious content. X-Frame-Options prevent your site from being displayed in a frame, averting clickjacking attacks.
      For any business, their website is the public face of the company. Website security maintains client trust by protecting their data and ensuring a safe browsing experience.
      `}
      whyDoesItMatterContent={`Caring about website security settings is crucial to prevent hacking, data theft, and malicious attacks, ensuring your website remains a safe, trustworthy platform.
      Without proper HTTP secure headers, your website could be vulnerable to attacks like cross-site scripting or data theft, potentially leading to compromised client data and loss of trust.
      `}
      howDoYouFixItContent={<>
      To implement HTTP secure headers on WordPress or similar platforms, either use security plugins that automatically add these headers or manually add them by modifying your website's .htaccess file or server configuration. Professional assistance is recommended for accuracy.
      <br /><br />
      Find the exact steps in our <Link href="https://www.icebergcyber.com/help-center-category/website-security" target="_blank" rel="noopener noreferrer">Help Center</Link>.
      </>}
      values={["Website Security Settings", values.ISHScore, values.ISHDetail]}
      cyberScore={cyberScore}
    />
  );
}
