import { useQuery } from "@tanstack/react-query";
import { CyberScoreCombined } from "./services/getCyberScoreCombined";
import { genReconKeyTakeaways } from "./services/genReconKeyTakeaways";

export function useReconKeyTakeawaysQuery(
  cyberScore: CyberScoreCombined | undefined,
  domain: string | undefined | null
) {
  const { data: keyTakeaways, ...query } = useQuery({
    queryKey: ["recon-key-takeaways", domain],
    queryFn: async () =>
      await genReconKeyTakeaways(cyberScore as CyberScoreCombined),
    enabled: !!cyberScore && !!domain,
  });

  return {
    keyTakeaways,
    ...query,
  };
}
