import {
  Flex,
  Heading,
  Card,
  Text,
  Accordion,
  Table,
  TableBody,
  TableRow,
  TableCell,
  ScrollView,
  View,
  Badge,
  Image,
  Divider,
  Button,
} from "@aws-amplify/ui-react";
import xMark from "../assets/images/pdf/cs-red-x.png";
import checkMark from "../assets/images/pdf/cs-green-check.png";
import notAvail from "../assets/images/pdf/cs-not-available.png";
import React, { useMemo } from "react";

import "./styling/cyberCenterContent.css";
import { Modal } from "./Modal";
import InformationPanel from "./InformationPanel";
import { CyberScoreCombined } from "../hooks/services/getCyberScoreCombined";
import CompromisedAccountsTable from "./CompromisedAccountsTable";
import { useBranding } from "../features/branding/hooks";
import { useCallToActionContext } from "../features/call-to-actions/contexts/CallToActionContext";
import { useViewAccess } from "../features/call-to-actions/hooks";

type CyberCenterContentProps = {
  whatIsItContent: React.ReactNode;
  whyDoesItMatterContent: React.ReactNode;
  howDoYouFixItContent: React.ReactNode;
  values: any;
  cyberScore: CyberScoreCombined;
};

type TableItem = {
  date: string;
  email: string;
  breachedAccount: string;
  description: string;
};

export default function CyberCenterContent(props: CyberCenterContentProps) {
  const {
    whatIsItContent,
    whyDoesItMatterContent,
    howDoYouFixItContent,
    values,
    cyberScore,
  } = props;

  const status = useMemo(
    () => (values[1] === 1 ? "pass" : values[1] === 0 ? "unknown" : "fail"),
    [values[1]]
  );
  const [selectedItem, setSelectedItem] = React.useState<TableItem | undefined>(
    undefined
  );

  const color = useMemo(
    () =>
      status === "pass"
        ? "#1acc85"
        : status === "unknown"
        ? "#A9AFBA"
        : "#fd323e",
    [status]
  );

  // const [activeTab, setActiveTab] = React.useState("darkwebreport");

  const modalRef = React.useRef<HTMLDialogElement>(null);

  const { openModal } = useCallToActionContext();

  const { branding } = useBranding();
  const { detailsHidden } = useViewAccess();

  return (
    <>
      <Modal
        ref={modalRef}
        size="medium"
        header={
          <Flex gap={"medium"} padding={"medium xxxl medium large"}>
            <Divider
              alignSelf={"stretch"}
              orientation="vertical"
              size="large"
              border={`5px solid ${branding?.accentColor}`}
              opacity={1}
              borderRadius={"medium"}
            />
            <View>
              <Heading level={4} color={"white"}>
                {selectedItem?.breachedAccount}
              </Heading>
              <Badge size="small">{selectedItem?.date}</Badge>
            </View>
          </Flex>
        }
      >
        <View
          dangerouslySetInnerHTML={{
            __html: selectedItem?.description ?? "",
          }}
        />
      </Modal>
      <Card
        // boxShadow={"-10px 10px #ccccff"}
        border={"1px solid #8FC3FF"}
        borderRadius={"large"}
        padding={"0"}
      >
        <Flex
          width={"100%"}
          direction={"column"}
          style={{ gap: "0 !important" }}
        >
          {/* ======== Header ========= */}
          <Flex
            width={"100%"}
            height={"55px"}
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={"medium"}
            boxShadow={"0px 1px #ccccff"}
          >
            <Heading id={`heading-${values[0].replace(/ /g, "-")}`} level={5}>
              {values ? values[0] : ""}
            </Heading>

            <Text fontSize={"xl"} padding={"0"} color={color}>
              <Image
                alt="status icon"
                src={
                  status === "pass"
                    ? checkMark
                    : status === "unknown"
                    ? notAvail
                    : xMark
                }
                style={{
                  width: "35px",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </Text>
          </Flex>
          {/* ======== Body ========= */}
          <Flex direction="column" padding={"medium"} gap="medium">
            <Text fontSize={"large"}>{values ? values[2] : ""}</Text>

            {values[0] === "Email Impersonation Protection" && (
              <Flex padding={"small 0 0 0"} direction={"column"}>
                <Text fontSize={"small"} fontWeight={"bold"}>
                  Records found:
                </Text>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell fontSize={"small"} as="th">
                        SPF
                      </TableCell>
                      <TableCell
                        fontSize={"smaller"}
                        style={{
                          wordBreak: "break-all",
                        }}
                      >
                        <ScrollView maxHeight={"40px"}>
                          {cyberScore?.SPF
                            ? cyberScore?.SPF
                            : "No SPF record found"}
                        </ScrollView>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontSize={"small"} as="th">
                        DKIM
                      </TableCell>
                      <TableCell
                        fontSize={"smaller"}
                        style={{
                          wordBreak: "break-all",
                        }}
                      >
                        <ScrollView maxHeight={"40px"}>
                          {cyberScore?.DKIM
                            ? cyberScore?.DKIM
                            : "No DKIM record found"}
                        </ScrollView>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontSize={"small"} as="th">
                        DMARC
                      </TableCell>
                      <TableCell
                        fontSize={"smaller"}
                        style={{
                          wordBreak: "break-all",
                        }}
                      >
                        <ScrollView maxHeight={"40px"}>
                          {cyberScore?.DMARC
                            ? cyberScore?.DMARC
                            : "No DMARC record found"}
                        </ScrollView>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Flex>
            )}

            {values[0] === "Dark Web Email Breaches" && (
              <Flex padding={"xs 0 0 0"} direction={"column"} gap={"xl"}>
                <InformationPanel
                  header={
                    <View>
                      <Heading level={4}>Dark Web Breaches</Heading>
                    </View>
                  }
                  body={
                    cyberScore.BreachList.length === 0 ? (
                      <Text>No breaches found</Text>
                    ) : (
                      <View position={"relative"}>
                        <ScrollView height="60vh" padding={"xs"}>
                          {detailsHidden ? (
                            <Flex
                              position={"absolute"}
                              top={"0"}
                              left={"0"}
                              width={"100%"}
                              height={"100%"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              style={{
                                pointerEvents: "none",
                              }}
                            >
                              <Button
                                variation="primary"
                                backgroundColor={branding.primaryColor}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openModal("dark-web-breaches-table");
                                }}
                                style={{
                                  pointerEvents: "auto",
                                  zIndex: 1,
                                }}
                              >
                                Unlock your full Score Card
                              </Button>
                            </Flex>
                          ) : null}
                          <Flex direction={"column"} alignItems={"stretch"}>
                            {cyberScore.BreachList.sort((a, b) => {
                              // sort by date
                              return (
                                new Date(b.BreachDate).getTime() -
                                new Date(a.BreachDate).getTime()
                              );
                            }).map((breach, i) => (
                              <Card
                                borderRadius={"medium"}
                                variation="elevated"
                                key={`${breach.Email}-${i}`}
                                onClick={() => {
                                  if (detailsHidden && i >= 2) {
                                    openModal("dark-web-breaches-table-blur");
                                    return;
                                  }
                                  setSelectedItem({
                                    date: breach.BreachDate,
                                    email: breach.Email,
                                    breachedAccount: breach.BreachName,
                                    description: breach.Description,
                                  });
                                  modalRef.current?.showModal();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <Flex justifyContent={"space-between"}>
                                  <Text
                                    flex={1}
                                    style={{
                                      filter:
                                        detailsHidden && i >= 2
                                          ? "blur(6px)"
                                          : undefined,
                                    }}
                                  >
                                    {breach.Email}
                                  </Text>
                                  <Text
                                    color={"GrayText"}
                                    style={{
                                      filter:
                                        detailsHidden && i >= 2
                                          ? "blur(6px)"
                                          : undefined,
                                    }}
                                  >
                                    {breach.BreachDate}
                                  </Text>
                                </Flex>
                                <Text
                                  fontWeight={"bold"}
                                  style={{
                                    filter:
                                      detailsHidden && i >= 2
                                        ? "blur(6px)"
                                        : undefined,
                                  }}
                                >
                                  {breach.BreachName}
                                </Text>
                              </Card>
                            ))}
                          </Flex>
                        </ScrollView>
                      </View>
                    )
                  }
                />
                <InformationPanel
                  header={
                    <View>
                      <Heading level={4}>Compromised Accounts</Heading>
                    </View>
                  }
                  body={<CompromisedAccountsTable maxHeight="60vh" />}
                />
              </Flex>
            )}
          </Flex>
          {/* ======== Footer ========= */}
          <Flex
            padding={"medium"}
            justifyContent={"space-between"}
            backgroundColor={"#EAF4FF"}
            borderRadius={"0 0 large large"}
            gap={"medium"}
          >
            <Accordion.Container
              allowMultiple
              width={"100%"}
              backgroundColor={"#EAF4FF"}
              defaultValue={["item-1"]}
            >
              <Accordion.Item value="item-1">
                <Accordion.Trigger fontWeight={"bold"}>
                  What is it?
                  <Accordion.Icon />
                </Accordion.Trigger>
                <Accordion.Content>{whatIsItContent}</Accordion.Content>
              </Accordion.Item>
              <Accordion.Item value="item-2">
                <Accordion.Trigger fontWeight={"bold"}>
                  Why does it matter?
                  <Accordion.Icon />
                </Accordion.Trigger>
                <Accordion.Content>{whyDoesItMatterContent}</Accordion.Content>
              </Accordion.Item>
              <Accordion.Item value="item-3">
                <Accordion.Trigger fontWeight={"bold"}>
                  How do you fix it?
                  <Accordion.Icon />
                </Accordion.Trigger>
                <Accordion.Content>{howDoYouFixItContent}</Accordion.Content>
              </Accordion.Item>
            </Accordion.Container>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
