import { Flex, View, Grid } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import QuickFactCard from "./QuickFactCard";

import { CyberScoreCombined } from "../hooks/services/getCyberScoreCombined";
import { BookingBubble } from "../features/call-to-actions";

import ScoreCardMarquee from "../features/call-to-actions/components/ScoreCardMarquee";

// import TableTitle from "./styling/TableTitle";

// redundant (create a folder for all of these I guess)/output of cutEmail function
// interface CyberCenterCardProps {
//   content: React.ReactNode | undefined;
// }

// const questions = [
//   "Uses Multi-Factor Authentication: Do you use Multi-Factor Authentication for all employee email accounts?",
//   "Employs Anti-Virus on Computers: Do you have anti-virus software installed on all employee computers?",
//   "Active Cyber Awareness Training: Do you train your employees with cyber awareness skills on at least a quarterly basis",
//   "Cyber Insurance Policy: Do you have an insurance policy covering the damages from cyber attacks?",
// ];

export default function CyberCenterCard(props: {
  cyberScore: CyberScoreCombined;
}) {
  const { cyberScore } = props;

  // allows for a change to the grid layout on different sizes, needs more use cases and testing
  // const [responsive, setResponsive] = useState({
  //   small: "0fr",
  //   large: "0fr 1fr 2fr 1fr",
  //   xl: "0fr 1fr 2fr 1fr",
  //   xxl: "0fr 1fr 2fr 1fr",
  // });

  // function to pass key to child components (ie, "ESS")
  // function descriptionCut(input: any) {
  //   let spaceIndex = input.indexOf(" ");
  //   let update = input.slice(0, spaceIndex);
  //   return update;
  // }

  // let insightsOnClick = descriptionCut(description);

  const [showBookingBubble, setShowBookingBubble] = useState(false);

  // const findAnMspModalRef = React.useRef<HTMLDialogElement>(null);

  // on scroll wait 10 seconds and then show modal
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     findAnMspModalRef.current?.showModal();
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // }, []);

  // const { data: claims } = useScoreClaimListQuery({ domain });

  // const approvedClaim = claims?.find(
  //   (claim: ScoreClaim) => claim.status === "approved"
  // );

  // const approvedAnswers: (-1 | 0 | 1)[] = approvedClaim
  //   ? [
  //       approvedClaim.mfaAnswer,
  //       approvedClaim.antiVirusAnswer,
  //       approvedClaim.trainingAnswer,
  //       approvedClaim.insuredAnswer,
  //     ]
  //   : [0, 0, 0, 0];

  return (
    <>
      {/* <FindAnMSPModal ref={findAnMspModalRef}  /> */}

      <BookingBubble show={showBookingBubble} setShow={setShowBookingBubble} />
      <ScoreCardMarquee cyberScore={cyberScore} source="marquee1" />

      <Flex
        direction={"column"}
        width={"100%"}
        maxWidth={"1080px"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingBottom={"xl"}
        gap={"0"}
      >
        <Grid
          // backgroundColor={'red.10'}
          templateColumns={{
            base: "1fr",
            large: "1fr 1fr",
          }}
          width={"100%"}
          padding={"xl"}
          paddingBottom={"0"}
          columnGap={"xxxl"}
          rowGap={"xl"}
        >
          <View
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .getElementById("BDV-card")
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          >
            <QuickFactCard
              score={cyberScore.BDVScore}
              padding="large"
              title={"Dark Web Breaches"}
              information={
                cyberScore.BDVScore === 1
                  ? `No accounts for sale on the Dark Web out of ${cyberScore.EmailList.length} total email addresses.`
                  : cyberScore.BDVScore === -1
                  ? `${cyberScore.BreachList.length} accounts for sale on the Dark Web out of ${cyberScore.EmailList.length} total email addresses.`
                  : "No email service associated with this domain."
              }
              right=""
              left="0"
              transform="translate(-50%, -50%)"
            />
          </View>

          <View
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .getElementById("ESS-card")
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          >
            <QuickFactCard
              score={cyberScore.ESSScore}
              padding="large"
              title={"Email Impersonation Security"}
              information={
                cyberScore.ESSScore === 1
                  ? "Email secured with DMARC protecting against spoofing and impersonations."
                  : cyberScore.ESSScore === -1
                  ? "Missing valid DMARC spoofing protection to prevent email impersonations."
                  : "No email service associated with this domain."
              }
              right="0"
              left=""
              transform="translate(50%, -50%)"
            />
          </View>

          <View
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .getElementById("SEG-card")
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          >
            <QuickFactCard
              score={cyberScore.SEGScore}
              padding="large"
              title={"Secure Email Gateway"}
              information={
                cyberScore.SEGScore === 1
                  ? cyberScore.SEG
                  : cyberScore.SEGScore === -1
                  ? `No email service detected that provides spam/phishing protection.`
                  : "No email service associated with this domain."
              }
              right=""
              left="0"
              transform="translate(-50%, -50%)"
            />
          </View>

          <View
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .getElementById("ISH-card")
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          >
            <QuickFactCard
              score={cyberScore.ISHScore}
              padding="large"
              title={"Website HTTP Security Settings"}
              information={
                cyberScore.ISHScore === 1
                  ? "Website securely configured to protect against malicious abuse."
                  : cyberScore.ISHScore === -1
                  ? `Missing ${
                      cyberScore.ISH.split(" ")[0]
                    } important settings to secure the site's visitor activity`
                  : "No website associated with this domain."
              }
              right="0"
              left=""
              transform="translate(50%, -50%)"
            />
          </View>

          <View
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .getElementById("WI-card")
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          >
            <QuickFactCard
              score={cyberScore.WIScore}
              padding="large"
              title={"Website SSL Encryption"}
              information={
                cyberScore.WIScore === 1
                  ? `Website traffic is encrypted until ${
                      cyberScore.WI.split(" ")[2]
                    } by an SSL certificate.`
                  : cyberScore.WIScore === -1
                  ? `Website missing SSL certificate exposing visitor traffic to data theft.`
                  : "No website associated with this domain."
              }
              right=""
              left="0"
              transform="translate(-50%, -50%)"
            />
          </View>

          <View
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .getElementById("WAF-card")
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          >
            <QuickFactCard
              score={cyberScore.WAFScore}
              padding="large"
              title={"Web Application Firewall"}
              information={
                cyberScore.WAFScore === 1
                  ? cyberScore.WAF
                  : cyberScore.WAFScore === -1
                  ? `The site is not protected from malicious visitors.`
                  : "No website associated with this domain."
              }
              right="0"
              left=""
              transform="translate(50%, -50%)"
            />
          </View>
        </Grid>
        {/* <Button
          padding={"xl"}
          backgroundColor={approvedClaim ? undefined : "transparent"}
          isDisabled={approvedClaim !== undefined}
          variation="link"
          border={"none"}
          boxShadow={"none"}
          borderRadius={"xl"}
          style={{
            cursor: approvedClaim ? "default" : "pointer",
          }}
          onClick={() => {
            if (!approvedClaim) bonusModal?.showModal();
          }}
        >
          <Flex direction={"column"} gap={"large"}>
            <Grid
              templateColumns={{ base: "1fr", large: "1fr 1fr" }}
              columnGap={"xxxl"}
              rowGap={"xl"}
            >
              {[
                {
                  information:
                    "Uses Multi-Factor Authentication for all employee email accounts.",
                  question: "Uses Multi-Factor Authentication",
                },
                {
                  information:
                    "Has Anti-Virus software installed on all employee computers.",
                  question: "Employs Anti-Virus on Computers",
                },
                {
                  information:
                    "Trains all employees with cyber awareness skills on at least a quarterly basis.",
                  question: "Active Cyber Awareness Training",
                },
                {
                  information:
                    "Has an insurance policy covering the damages from cyber attacks.",
                  question: "Cyber Insurance Policy",
                },
              ].map(({ question, information }, i) => (
                <View key={i}>
                  <QuestionCard
                    score={approvedAnswers[i]}
                    question={question}
                    information={information}
                    right={i % 2 === 0 ? "" : "0"}
                    left={i % 2 === 0 ? "0" : ""}
                    transform={
                      i % 2 === 0
                        ? "translate(-50%, -50%)"
                        : "translate(50%, -50%)"
                    }
                  />
                </View>
              ))}
            </Grid>
          </Flex>
        </Button> */}
      </Flex>
      <ScoreCardMarquee cyberScore={cyberScore} source="marquee2" />
      {/* {!approvedClaim ? (
        // <Marquee>
        //   <Flex
        //     alignItems={"center"}
        //     fontWeight={"bold"}
        //     justifyContent={"center"}
        //     color={"white"}
        //     fontSize={"large"}
        //     padding={"0 small 0 small"}
        //     direction={{ base: "column", large: "row" }}
        //   >
        //     <Text
        //       fontSize={{ base: "medium", large: "xl" }}
        //       width={{ base: "auto", large: "325px" }}
        //       fontWeight={"bold"}
        //       color={"white"}
        //       style={{
        //         textShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
        //       }}
        //     >
        //       Is this your company? <br />
        //       Take this survey to instantly
        //     </Text>
        //     <Flex>
        //       <FaArrowRight color={"white"} size={"2em"} />
        //       <Button
        //         className="cta-button"
        //         color={branding?.accentColor || "#144EE3"}
        //         borderRadius={"medium"}
        //         backgroundColor="white"
        //         fontSize={"large"}
        //         data-attr="improve-score"
        //         data-ph-capture-attribute-domain={domain}
        //         onClick={() => {
        //           bonusModal?.showModal();
        //         }}
        //       >
        //         🚀 Improve Your Score!
        //       </Button>
        //     </Flex>
        //   </Flex>
        // </Marquee>
      ) : null} */}
    </>
  );
}
