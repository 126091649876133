// import axios from "axios";

import { getEnvironment } from "../../services/getEnvironment";
import { invokeLambda } from "../util/invokeFunction";

/**
 * Fetches an image URL and returns a base64 string representation.
 * @param url The URL of the image to convert to base64.
 * @returns A Promise that resolves to the base64 string of the image.
 */
export async function getImageToBase64(url: string): Promise<string> {
  try {
    // const response = await axios.post(lambdaEndpoint as string, { url });
    const env = await getEnvironment();
    const response = await invokeLambda(`SpaImageFunc-${env}`, {
      body: { url },
    });

    const base64Image = JSON.parse(response.body);

    return base64Image.imageSrc;
  } catch (error) {
    console.error("Error fetching image:", error);
    throw new Error("Error fetching image.");
  }
}
