import { Flex, Text, Image } from "@aws-amplify/ui-react";
import * as echarts from "echarts";
import React, { useEffect, useMemo, useRef } from "react";
import "./styling/cyberscorecard.css";
import transformSnakeInput from "../hooks/util/transformSnakeInput";
import { CyberScoreCombined } from "../hooks/services/getCyberScoreCombined";
import { useBranding } from "../features/branding/hooks";

export interface CyberScoreGraphsInput {
  cyberScore: CyberScoreCombined | undefined;
}

export default function CyberScoreGraphs({
  cyberScore,
}: CyberScoreGraphsInput) {
  const chartRef = useRef(null);
  // const { domain } = useParams();
  const { branding } = useBranding();

  // const { data: claims } = useScoreClaimListQuery({ domain });

  // const approvedClaim = claims?.find((claim) => claim.status === "approved");

  // const finalScore = useMemo(() => {
  //   if (!cyberScore) return 0;
  //   if (approvedClaim) {
  //     const bonus =
  //       [
  //         approvedClaim.mfaAnswer,
  //         approvedClaim.antiVirusAnswer,
  //         approvedClaim.trainingAnswer,
  //         approvedClaim.insuredAnswer,
  //       ].filter((answer) => answer === 1).length * 10;

  //     const base = cyberScore.Score * 0.6;
  //     return base + bonus;
  //   }
  //   return cyberScore.Score;
  // }, [approvedClaim, cyberScore]);

  // on render the graph is created
  useEffect(() => {
    // Check if the chartRef is currently pointing to a DOM element, and ensure that the 'output' array is defined and not empty
    if (chartRef.current && output && output.length > 0) {
      let myChart = echarts.init(chartRef.current, null, {
        renderer: "svg",
      });

      // apache chart data
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}",
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: false, readOnly: true },
            restore: { show: false },
            saveAsImage: { show: false }, //turned to false, took up space in the field
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: [15, 90],
            center: ["60%", "50%"],
            roseType: "radius",
            itemStyle: {
              borderRadius: 5,
            },
            label: {
              show: false,
            },
            data: [
              {
                value: 14,
                name: `${output[0].title} - ${output[0].status}`,
                itemStyle: {
                  color: `rgb(${output[0].color},.9)`,
                },
              },
              {
                value: 13,
                name: `${output[1].title} - ${output[1].status}`,
                itemStyle: {
                  color: `rgb(${output[1].color},.8)`,
                },
              },
              {
                value: 12,
                name: `${output[2].title} - ${output[2].status}`,
                itemStyle: {
                  color: `rgb(${output[2].color},.7)`,
                },
              },
              {
                value: 11,
                name: `${output[3].title} - ${output[3].status}`,
                itemStyle: {
                  color: `rgb(${output[3].color},.6)`,
                },
              },
              {
                value: 10,
                name: `${output[4].title} - ${output[4].status}`,
                itemStyle: {
                  color: `rgb(${output[4].color},.5)`,
                },
              },
              {
                value: 9,
                name: `${output[5].title} - ${output[5].status}`,
                itemStyle: {
                  color: `rgb(${output[5].color},.4)`,
                },
              },
              {
                value: 8,
                name: `${output[6].title} - ${output[6].status}`,
                itemStyle: {
                  color: `rgb(${output[6].color},.3)`,
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    }
  }, [cyberScore]);

  // invoke function and give result a variable used within the chartRef in the useEffect
  // in helperFunctions folder
  let output = useMemo(() => {
    if (cyberScore) return transformSnakeInput(cyberScore);
    else
      return [0, 1, 2, 3, 4, 5, 6].map(() => ({
        key: "",
        value: 0,
        status: "",
        color: "",
        title: "",
      }));
  }, [cyberScore]);

  // attempt at a responsive design below based off the amplify breakpoints
  // ICEAPP-676 shows the resolutions used
  return (
    <>
      {/* <Card boxShadow={"medium"} borderRadius={"large"}> */}
      <Flex
        id="fuel-gauge"
        direction={"column"}
        alignItems={"center"}
        justifyContent={"end"}
        position={"relative"}
        gap={"large"}
        height={{
          base: "150px",
          large: "180px",
        }}
        style={{
          aspectRatio: "40/17",
        }}
      >
        <Image
          alt="gauge"
          src={`/images/gauge.png`}
          position={"absolute"}
          top={0}
          left={0}
          objectFit={"contain"}
          height={"100%"}
          width={"100%"}
        ></Image>
        <Text
          fontSize={{ base: "55px", large: "64px" }}
          fontWeight={"bold"}
          height={{ base: "60px", large: "70px" }}
        >
          {cyberScore?.Score.toFixed(0)}%
        </Text>
        <Text
          // maxWidth={"200px"}
          lineHeight={"1em"}
          fontSize={{ base: "20px", large: "25px" }}
          textAlign={"center"}
          fontWeight={"bold"}
          color={"neutral.80"}
        >
          {branding.pdfTitle ?? "Cyber Score"}
        </Text>
      </Flex>
    </>
  );
}
