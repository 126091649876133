import { View, Flex, Divider, Button } from "@aws-amplify/ui-react";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { useBranding } from "../features/branding/hooks";

type InformationPanelProps = {
  header: React.ReactNode;
  body: React.ReactNode;
  onClose?: () => void;
};

export default function InformationPanel({
  header,
  body,
  onClose,
}: InformationPanelProps) {
  const { branding } = useBranding();

  return (
    <View>
      <Flex
        width={"100%"}
        alignItems={"start"}
        justifyContent={"space-between"}
      >
        <Flex gap={"xs"} alignItems={"inherit"}>
          <Divider
            alignSelf={"stretch"}
            orientation="vertical"
            size="large"
            border={`5px solid ${branding?.accentColor}`}
            opacity={1}
            borderRadius={"medium"}
          />
          {header}
        </Flex>
        {onClose ? (
          <Button variation="menu" onClick={onClose}>
            <FaTimes />
          </Button>
        ) : null}
      </Flex>
      <View paddingTop={"medium"}>{body}</View>
    </View>
  );
}
