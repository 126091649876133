import { useEffect } from 'react';
import { useOpenAiResponseQuery } from "./useOpenAiQuery";
import { useData } from '../components/CTA/DataContext';

// export function testPeterGen() {

  //Placeholder user input
  // const placeholderUserInput = {
  //   domainScore: {
  //     "WI": "Valid to 2024-12-04 23:59:59",
  //     "PS": "Not using WordPress",
  //     "WAF": "Kona SiteDefender (Akamai) firewall detected",
  //     "ISH": "11 missing security website headers found",
  //     "ESS": "Insufficient DMARC records",
  //     "SPF": "v=spf1 ip4:207.218.164.0/24 ip4:207.218.165.0/24 ip4:40.118.191.72/32 ip4:104.210.54.164/32 ip4:13.93.234.61 ip4:13.91.103.9 ip4:166.77.103.0/24 ip4:206.132.214.0/24 ip4:23.96.34.227 ip4:104.40.80.160 ip4:52.160.86.51\" \"include:mail.zendesk.com include:_spf.google.com ~all",
  //     "DKIM": "k=rsa; t=s; p=MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnDFslj+GEk2uL4QDE9MPnaTcQ82XtT35Mw4qDUaOqJm0itAJi8RX9t8X3+41gQGApKQHWpJI4koauT0ygqNNZ/+lweL+LVgA8WvpS3I6vXzax8mPlLHvvf5B4tmA5R3dN2BA+zKthnIL7in0wlEV8unnFxohlHnMrOJV8/nkCSUpuhyUvrHHgE64ZwRLQVtZh42DX\" \"2lAIAeUfTgFWaIzq4ot6spTMkM2n4PElR4MNAghP98f4pL/y667UD3dg9B9JzgjwCmPwCuZvAA3kL9/DgMiAGAahoYaoceCLKoBxzrJDksssSvj5wC4GCi3M4wEQ3x0p9ItYy3DSlv4coij9wIDAQAB",
  //     "DMARC": "v=DMARC1; p=none;",
  //     "SEG": "No secure email gateway detected",
  //     "BDV": "58 breaches out of 20 total accounts",
  //     "Score": 33
  //   },
  //   surveyData: {
  //     employeesCount: {
  //       question: "How many employees do you have?",
  //       answer: "< 10",
  //       options: ["< 10", "11 - 20", "21 - 50", "> 50"],
  //     },
  //     annualRevenue: {
  //       question: "What is your organization's annual revenue?",
  //       answer: "< $500k",
  //       options: ["< $500k", "$500k - $1M", "$1M - $5M", "> $5M"],
  //     },
  //     industry: {
  //       question: "What industry is your organization in?",
  //       answer: "Retail"
  //     },
  //     // mfaBankAccount: {
  //     //   question: "Does your organization use multi-factor authentication for bank accounts?",
  //     //   answer: true
  //     // },
  //     // mfaEmailAccounts: {
  //     //   question: "Does your organization use multi-factor authentication for email accounts?",
  //     //   answer: false
  //     // },
  //     // conductingBackups: {
  //     //   question: "Is your organization conducting backups?",
  //     //   answer: false
  //     // },
  //     // updatingDevices: {
  //     //   question: "Is your organization updating devices?",
  //     //   answer: false
  //     // },
  //     // itHandler: {
  //     //   question: "Who is handling your IT?",
  //     //   answer: "We figure it out as we go",
  //     //   options: ["We figure it out as we go", "One of our employee's cousins", "We have a trained IT person on staff", "We outsource to an IT company"],
  //     // },
  //     // itBudgetImportance: {
  //     //   question: "How important is IT in your monthly budget?",
  //     //   answer: "$50/user: I'm trying to pinch pennies",
  //     //   options: ["$50/user: I'm trying to pinch pennies", "$100/user: Squared away with the basics", "$150/user: On the ball with client data protection", "$200/user: I need IT to never be a headache"],
  //     // }
  //   }
  // };

  // const peterResponses = getOpenAiResponse(placeholderUserInput, "Teaser");


//   return;
// }

export default function usePeterGenerator({ responseType }: any) {

  const { data, questions, handleAnswer, cyberScoreContext } = useData();

  const domainScore = {
    "SSLcert": cyberScoreContext?.WI,
    "WPPluginCheck": cyberScoreContext?.PS,
    "Firewall": cyberScoreContext?.WAF,
    "SecurityHeaders": cyberScoreContext?.ISH,
    "EmailSecurity": cyberScoreContext?.ESS,
    "SPF": cyberScoreContext?.SPF,
    "DKIM": cyberScoreContext?.DKIM,
    "DMARC": cyberScoreContext?.DMARC,
    "EmailGateway": cyberScoreContext?.SEG,
    "DataBreaches": cyberScoreContext?.BDV,
    "Score": `${cyberScoreContext?.Score}%`
  }

  // For "insights" card in survey
  const teaserObject = {
    domainScore: domainScore,
    surveyData: {
      employeesCount: questions?.employeesCount,
      annualRevenue: questions?.annualRevenue,
      industry: questions?.industry,
    }
  }

  // For use in PDF
  const fullObject = {
    domainScore: domainScore,
    surveyData: questions
  }

  // Determine which object to pass based on responseType
  const queryObject = responseType === 'Teaser' ? teaserObject : fullObject;

  const { response, isLoading, isError } = useOpenAiResponseQuery(queryObject, responseType);


  useEffect(() => {
    if (responseType === "Teaser" && !data.teaser) {
      if (!isLoading && !isError && response && 'insight' in response) {
        handleAnswer('teaser', response.insight[0].content);
      }
    }

    if (responseType === "Full" && data.objectives.length == 0) {
      if (!isLoading && !isError && response && 'objectives' in response) {
        handleAnswer('objectives', response.objectives);
      }
    }

  }, [response, isLoading, isError, handleAnswer, data.objectives, data.teaser, responseType]);

  return { isLoading, response, isError };
}