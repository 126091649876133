import { useQuery } from "@tanstack/react-query";
import { getOpenAiResponse } from "./services/getOpenAiResponse";

interface TeaserResponse {
  insight: [
    {
      content: string;
    }
  ];
}

interface FullResponse {
  objectives: [
    {
      name: string;
      content: string;
    }
  ];
}

// Create a union type for the OpenAI response
type OpenAiResponse = TeaserResponse | FullResponse;

export function useOpenAiResponseQuery(
  questions?: any,
  responseType?: string
) {

  const { data: response, isLoading, isError } = useQuery<OpenAiResponse>({
    queryKey: ["open-ai-response", { questions, responseType }],
    queryFn: async () => await getOpenAiResponse(questions, responseType),
    enabled: !!questions,
  })

  return { response, isLoading, isError };
}