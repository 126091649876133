import React from "react";
import CyberCenterContent from "./CyberCenterContent";

export default function SecureEmailGatewayInfo(props: { values: any; cyberScore: any}) {
  const { values, cyberScore } = props;

  return (
    <CyberCenterContent
      whatIsItContent={`A Secure Email Gateway (SEG) is a tool that filters unwanted or harmful emails. It blocks spam, phishing attempts, and malware from reaching your inbox, enhancing email security.
      For any business, protecting sensitive financial data is crucial. An SEG helps prevent email-based threats that could compromise this data.
      `}
      whyDoesItMatterContent={`Email phishing is the #1 threat impacting small businesses. They deal with this on a daily basis. Caring about an SEG means actively defending your business against email scams and cyber threats, which are common and potentially very damaging.
      Without an SEG, you're at higher risk of email scams and malware. This could lead to data breaches, financial loss, and damage to your business reputation.
      `}
      howDoYouFixItContent={`Purchasing an SEG through your IT provider offers advanced filtering of harmful emails, significantly reducing the risk of phishing, malware, and data breaches. This protection is a worthwhile investment as it safeguards your sensitive accounting data, maintains client trust, and prevents potential financial and reputational damage to your business.
      `}
      values={["Secure Email Gateway", values.SEGScore, values.SEGDetail]}
      cyberScore={cyberScore}
    />
  );
}
