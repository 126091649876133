import {  useQuery } from "@tanstack/react-query";
import { getPortHealth } from "../services";

export function usePortHealth(ip?: string) {
  const { data: ports, ...query } = useQuery({
    queryKey: ["ports", ip],
    queryFn: () => getPortHealth(ip as string),
    throwOnError: false,
    enabled: !!ip,
  });

  return {
    ports: ports,
    ...query,
  };
}
