import React, {useState} from "react";
import Dropdown from "../Dropdown";
import P3 from "./P3";
import P5 from "./P5";
import { useData } from '../DataContext';

export default function P4() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState(false);

  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);

  const { handleAnswer } = useData();

  const onAnswerAndUpdateNext = (answer: any) => {
    handleAnswer('industry', answer);
  };

  return (
    <>
      {back ? (
        <P3 />
      ) : (
        <>
          {next ? (
            <P5 />
          ) : (
            <Dropdown
              onNextChange={handleNextChange}
              onBackChange={handleBackChange}
              onAnswer={onAnswerAndUpdateNext}
            />
          )}
        </>
      )}
    </>
  );
}
