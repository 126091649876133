import React, { useState } from "react";
import Intro from "../Intro";
import P2 from "./P2";

export default function P1() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState("");
  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);
  return (
    <>
      {next ? (
        <P2 />
      ) : (
        <Intro
          onNextChange={handleNextChange}
          onBackChange={handleBackChange}
          Response={"I1"}
        />
      )}
      {back ? "" : ""}
    </>
  );
}
