import { get } from "../../../hooks/util";
import { PortMapping } from "../types";

export async function getPortHealth(ip: string): Promise<PortMapping[]> {
  try {
    const response = await get("PortAPI", `/shodan/port-health/${ip}`).response;
    const object = await response.body.json();
    return object as PortMapping[];
  } catch (error) {
    throw error;
  }
}
