import React from "react";
import { Link } from '@aws-amplify/ui-react';
import CyberCenterContent from "./CyberCenterContent";

export default function WordPressPluginSecurityInfo(props: { values: any; cyberScore: any}) {
  const { values, cyberScore } = props;

  return (
    <CyberCenterContent
      whatIsItContent={`Keeping WordPress plugins up-to-date ensures they have the latest security fixes and features. Updates often patch vulnerabilities that could be exploited by hackers.
      Your website is the public face of your business and can perform crucial functions like appointment booking, E-commerce, and marketing. Safeguarding client data and ensuring uninterrupted services are crucial. Updated plugins help maintain a secure, functional website.
      `}
      whyDoesItMatterContent={`WordPress is used on almost 50% of all websites on the Internet. This makes it a very popular targer for criminals. Website plugin security is vital to prevent hacking and data breaches. Secure plugins protect both your business's and your clients' sensitive information.
      Not updating plugins can lead to security breaches, where hackers might steal sensitive data, disrupt your website, or use it to spread malware.
      `}
      howDoYouFixItContent={<>
      To keep WordPress plugins updated, regularly check your WordPress dashboard for available updates and apply them. Alternatively, enable auto-updates for plugins to ensure they're always current.
      <br /><br />
      Find the exact steps in our <Link href="https://www.icebergcyber.com/help-center-category/website-security" target="_blank" rel="noopener noreferrer">Help Center</Link>.
      </>}
      values={["WordPress Plugin Security", values.PSScore, values.PSDetail]}
      cyberScore={cyberScore}
    />
  );
}
