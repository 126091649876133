import { Card, Flex, Image, Placeholder, Text } from "@aws-amplify/ui-react";
import React from "react";

import { DomainProps } from "./Interfaces";
import { useFavicon } from "../hooks/useFavicon";
import CyberScoreGraphs from "./CyberScoreGraphs";
import { useBranding } from "../features/branding/hooks";

// do we need to use the useFavicon and this type (below) for this page?
// kind of makes sense to make it simple and only worry about the URL/type = DomainProps
// export type Company = {
//     companyId: string;
//     companyName: string;
//     scannerId: string;
//     domain: string;
// }

export default function CompanyInformation({
  domain,
  cyberScore,
}: DomainProps) {
  const { data: backupLogo, isLoading } = useFavicon(domain);

  const { branding } = useBranding();

  function truncateString(str: string | undefined, maxLength: number): string {
    if (!str) return "";
    return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  }

  return (
    <Flex justifyContent={"center"} padding={"0"}>
      <Card
        transform={{
          base: "translate(5px, -15px)",
          large: "translate(-10px, -15px)",
        }}
        variation="outlined"
        padding={"large"}
        borderWidth={"2px"}
        borderColor={branding?.accentColor ?? "#144EE3"}
        boxShadow={`-10px 10px ${branding?.accentColor ?? "#144EE3"}`}
        borderRadius={"large"}
        // marginRight={"-50px"}
        style={{
          zIndex: 2,
        }}
      >
        <Flex
          width={"100%"}
          justifyContent={"space-around"}
          direction={{ base: "column-reverse", large: "column" }}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            direction={"row"}
            gap={"small"}
          >
            <Card
              boxShadow={"medium"}
              borderRadius={"medium"}
              padding={"small"}
            >
              <Flex alignItems={"center"}>
                {isLoading ? (
                  <Placeholder height={"40px"} width={"40px"} />
                ) : (
                  <Image
                    alt="company logo"
                    src={cyberScore?.Logo ?? backupLogo}
                    objectFit="contain"
                    objectPosition="50% 50%"
                    height="40px"
                    width="40px"
                  />
                )}
              </Flex>
            </Card>
            <Text
              fontWeight={"bold"}
              fontSize={{ base: "24px", large: "28px" }}
              color={"blue.90"}
            >
              {truncateString(domain, 30)}
            </Text>
          </Flex>
          <Flex
            alignItems={"center"}
            justifyContent={{ base: "center", large: "" }}
          >
            <CyberScoreGraphs cyberScore={cyberScore} />
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}
