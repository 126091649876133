import { useQuery } from "@tanstack/react-query";
import { getIP } from "../services";

export function useIP() {
  const { data: ip, ...query } = useQuery({
    queryKey: ["ip"],
    queryFn: () => getIP(),
  });

  return {
    ip,
    ...query,
  };
}
