import React from "react";
import { Flex, Grid, Text } from "@aws-amplify/ui-react";
import TxtButton from "./ui_components/TxtButton";
import cs1 from "./assets/cs1.png";
// import "./ui_components/component.css";
import blueprint from "./animations/blueprint_small.gif";
import target from "./animations/target_small.gif";

export default function Intro({ onNextChange, onBackChange, Response }: any) {
  let txt1 = "";
  let txt2 = "";
  let image = "";
  let type = "bottom";

  if (Response === "I1") {
    txt1 = "Let's start the journey on your Cyber Roadmap!";
    txt2 = "";
    type = "Placeholder";
    image = blueprint;
  } else {
    txt1 = "Let's drill into your Cyber Basics...";
    txt2 = "";
    image = target;
  }

  return (
    <>
      <Flex
        direction={"column"}
        gap="0"
        alignItems={"center"}
        className="page"
        backgroundColor={"#144EE3"}
        height={{ base: "100vh", large: "90vh" }}
        width={"100vw"}
        maxWidth={{ base: "100vw", large: "600px" }}
        maxHeight={{ large: "900px" }}
      >
        <Grid
          height={{ base: "85vh", medium: "75vh", large: "75vh" }}
          width={"70vw"}
          maxWidth={"500px"}
          // templateRows={{
          //   base: "3fr 10fr",
          //   medium: "2fr 6fr",
          //   large: "2fr 6fr",
          // }}
          templateColumns={"auto"}
          paddingTop={{ base: "20%", medium: "10%", large: "10%" }}
        >
          <img
            src={cs1}
            height="100px"
            width="100px"
            alt="Cyber Score Image"
            style={{
              borderRadius: "50%",
              boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
              gridRow: "1",
              justifySelf: "center",
            }}
          />
          <Flex justifyContent={"center"} direction={"column"}>
            <Text
              color={"white"}
              textAlign={"center"}
              // fontSize={"large"}
              className="headers"
              fontWeight={"bold"}
              fontSize={{base: "large", large: "xxl"}}
              style={{ gridRow: "2" }}
            >
              {txt1}
            </Text>
            <Text
              color={"white"}
              textAlign={"center"}
              // fontSize={"large"}
              // className="headers"
              fontWeight={"bold"}
              fontSize={{base:"medium", large:"large"}}
              style={{ gridRow: "2" }}
            >
              {txt2}
            </Text>
          </Flex>
          <Flex justifyContent={"center"}>
            <img src={image} height={"200px"} alt="" />
          </Flex>
        </Grid>
        <Grid
          height={"15vh"}
          width={"100vw"}
          backgroundColor={"white"}
          justifyContent={"center"}
        >
          <Grid
            width={{
              base: "100%",
              large: "100%"
            }}
            templateColumns={"5fr 5fr"}
            // paddingLeft={"15px"}
          >
            <TxtButton
              setter={() => onBackChange(true)}
              txt={"Back"}
              type={type}
            />
            <TxtButton
              setter={() => onNextChange(true)}
              txt={"Next"}
              type={"bottom"}
            />
          </Grid>
        </Grid>
      </Flex>
    </>
  );
}
