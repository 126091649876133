import {
  Button,
  Card,
  Flex,
  Grid,
  Image,
  View,
  Text,
  Heading,
} from "@aws-amplify/ui-react";
import * as React from "react";
import { useBranding } from "../../../branding/hooks";
import { FaRegMessage } from "react-icons/fa6";
import { useParams } from "react-router-dom";

export interface IBookingBubbleProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

export function BookingBubble({ show, setShow }: IBookingBubbleProps) {
  const { branding, brandingId } = useBranding();

  const { domain } = useParams();

  // useDebounce(() => {
  //   setShow(true);
  // }, 10000);

  if (brandingId && (!branding?.bookingLink || !branding?.contactImage)) {
    return null;
  }

  return (
    <View
      position={"fixed"}
      bottom={"large"}
      right={"large"}
      style={{ zIndex: 100 }}
    >
      <View position={"relative"} height={{ base: "70px", large: "100px" }}>
        {show ? (
          <Card
            boxShadow={"large"}
            position={"absolute"}
            bottom={{ base: "50px", large: "75px" }}
            right={{ base: "50px", large: "75px" }}
            borderRadius={"large large xs large"}
            padding={"0 0 medium 0"}
            style={{ zIndex: -1 }}
            overflow={"hidden"}
          >
            <Grid templateRows={"auto auto"} gap={"medium"} width={"250px"}>
              <Flex
                width={"100%"}
                backgroundColor={branding?.primaryColor ?? "#0B101B"}
                height={"50px"}
                alignItems={"center"}
                padding={"small medium"}
              >
                <FaRegMessage size={"15px"} color="white" />
                <Heading
                  color={"white"}
                  fontWeight={"semibold"}
                  style={{
                    textShadow: "1px 1px 2px rgba(0,0,0,0.4)",
                  }}
                >
                  {branding?.contactName ?? "Michael Bakaic"}
                </Heading>
              </Flex>
              <View padding={"0 medium"}>
                <Flex
                  direction={"column"}
                  backgroundColor={"background.tertiary"}
                  borderRadius={"xl xl xs xl"}
                  padding={"small"}
                >
                  <Text>Need some 1 on 1 help to improve your Score?</Text>
                  <Button
                    variation="primary"
                    backgroundColor={branding?.accentColor ?? "#144EE3"}
                    borderRadius={"large"}
                    data-attr="book-call"
                    data-ph-capture-attribute-domain={domain}
                    data-ph-capture-attribute-mspname={
                      branding?.mspName ?? "The Cyber Score"
                    }
                    data-ph-capture-attribute-mspemail={
                      branding?.contactEmail ?? "michael@thecyberscore.com"
                    }
                    data-ph-capture-attribute-mpsid={branding?.memberId}
                    onClick={() =>
                      window.open(
                        branding?.bookingLink ??
                          "https://calendly.com/cyberscore",
                        "_blank"
                      )
                    }
                  >
                    📞 Book a call
                  </Button>
                </Flex>
              </View>
            </Grid>
          </Card>
        ) : null}
        <Button
          border={"none"}
          boxShadow={"none"}
          height={{ base: "70px", large: "100px" }}
          borderRadius={"100px"}
          padding={"0"}
          onClick={() => setShow(!show)}
          style={{ aspectRatio: "1", zIndex: 200 }}
        >
          <Image
            alt="contact image"
            src={
              branding?.contactImage ??
              "https://uploads-ssl.webflow.com/65c0165efb82828c19b0faf0/66678d31a3b21d7fbba88340_LI%20Profile%20Pic%20June%202024%208.png"
            }
            borderRadius={"100px"}
            objectFit={"cover"}
            width={"100%"}
            height={"100%"}
          ></Image>
        </Button>
      </View>
    </View>
  );
}
