import { Image, Text, Card } from "@aws-amplify/ui-react";
import React, { ReactNode } from "react";
import { getScoreImage } from "./pdf/v2/util/getScoreImage";

export default function QuickFactCard({
  score,
  padding,
  title,
  information,
  left,
  right,
  transform,
}: {
  score: -1 | 0 | 1;
  padding: string;
  title: string;
  information: ReactNode;
  transform: string;
  left: string;
  right: string;
}) {
  return (
    <>
      <Card
        boxShadow={"large"}
        borderRadius="medium"
        padding={padding}
        position={"relative"}
        height={"100%"}
      >
        <Image
          alt=""
          src={getScoreImage(score)}
          borderRadius={"100%"}
          height={"50px"}
          width={"50px"}
          position={"absolute"}
          backgroundColor={"white"}
          textAlign={"center"}
          top={"0"}
          right={right}
          left={left}
          transform={transform}
        ></Image>
        {/* <Card borderRadius={"100%"} height={"50px"} width={"50px"}
          position={"absolute"} backgroundColor={"#000080"}
          textAlign={"center"}
          top={"0"} right={right} left={left} transform={transform}>
        <Text color={"white"}>{icon}</Text>

      </Card> */}
        <Text
          fontWeight={"bold"}
          color={
            score === -1 ? "#ff343a" : score === 0 ? "GrayText" : "green.60"
          }
        >
          {title}
        </Text>
        <Text>{information}</Text>
      </Card>
    </>
  );
}
