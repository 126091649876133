import React, { useState } from "react";
import P1 from "./P1";
import P3 from "./P3";
import Questions from "../Questions";
import { useData } from '../DataContext';

export default function P2() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState(false);

  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);

  const { handleAnswer } = useData();

  const onAnswerAndUpdateNext = (answer: any) => {
    handleAnswer('employeesCount', answer);
    setNext(true);
  };
  

  return (
    <>
      {back ? (
        <P1 />
      ) : (
        <>
          {next ? (
            <P3 />
          ) : (
            <Questions
              onNextChange={handleNextChange}
              onAnswer={onAnswerAndUpdateNext}
              onBackChange={handleBackChange}
              Response="Q1"
            />
          )}
        </>
      )}
    </>
  );
}
