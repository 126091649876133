import { Flex, View } from "@aws-amplify/ui-react";
import * as React from "react";
import { useBranding } from "../features/branding/hooks";

export interface IMarqueeProps {
  children?: React.ReactNode;
}

export function Marquee({ children }: IMarqueeProps) {
  const { branding } = useBranding();

  return (
    <View width={"100%"}>
      <Flex
        width={"100%"}
        alignItems="center"
        justifyContent={"center"}
        gap={"xxl"}
        backgroundColor={branding?.accentColor || "#144EE3"}
        padding={"large"}
      >
        {children}
      </Flex>
    </View>
  );
}
