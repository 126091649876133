import React, { useState } from "react";
import P9 from "./P9";
import P11 from "./P11";
import Loader from "../Loader";

export default function P10() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState(false);
  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);

  return (
    <>
      {back ? (
        <P9 />
      ) : (
        <>
          {next ? (
            <P11 />
          ) : (
            <Loader
              onNextChange={handleNextChange}
              onBackChange={handleBackChange}
            />
          )}
        </>
      )}
    </>
  );
}
