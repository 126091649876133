import React, { useState } from "react";
import P11 from "./P11";
import { Flex, Grid, Text, Button } from "@aws-amplify/ui-react";
import cs1 from "../assets/cs1.png";
import TxtButton from "../ui_components/TxtButton";
import "../ui_components/component.css"
import { FaArrowsTurnRight } from "react-icons/fa6";
import { useData } from "../DataContext";

export default function P12() {
  const [back, setBack] = useState("");
  const handleBackChange = (value: any) => setBack(value);
  const { handleAnswer, handleRoadmap } = useData();

  const resetAnswers = () => {
    handleAnswer('employeesCount', "");
    handleAnswer('annualRevenue', "");
    handleAnswer('domain', "");
    handleAnswer('sessionId', "");
    handleAnswer('industry', "")
    handleAnswer('mfaBankAccount', false)
    handleAnswer('mfaEmailAccounts', false)
    handleAnswer('conductingBackups', false)
    handleAnswer('updatingDevices', false)
    handleAnswer('itHandler', "")
    handleAnswer('itBudgetImportance', "")
    handleAnswer('email', "")
    handleRoadmap(false)
  }

  return (
    <>
      {back ? (
        <P11 />
      ) : (
        <Flex
          direction={"column"}
          gap="0"
          alignItems={"center"}
          className="page"
          backgroundColor={"#144EE3"}
          height={{ base: "100vh", large: "90vh" }}
          width={"100vw"}
          maxWidth={{ base: "100vw", large: "600px" }}
          maxHeight={{large: "900px"}}
        >
          <Grid
            height={{ base: "85vh", medium: "75vh", large: "75vh" }}
            width={"70vw"}
            templateRows={{
              base: "3fr 1fr 9fr",
              medium: "2fr 1fr 6fr",
              large: "2fr 1fr 6fr",
            }}
            paddingTop={{ base: "20%", medium: "10%", large: "10%" }}
            templateColumns={"auto"}
          >
            <img
              src={cs1}
              height="100px"
              width="100px"
              alt="Cyber Score Image"
              style={{
                borderRadius: "50%",
                boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
                gridRow: "1",
                justifySelf: "center",
              }}
            />
            <Flex justifyContent={"center"} alignItems={"center"} direction={"column"}>
              <Text
                color={"white"}
                textAlign={"center"}
                // fontSize={"large"}
                fontWeight={"bold"}
                fontSize={"22px"}
              >
                Your Roadmap is downloading... 
              </Text>
              <Text
                color={"white"}
                textAlign={"center"}
                // fontSize={"large"}
                fontWeight={"bold"}
                fontSize={"22px"}
              >
                Go check it out!
              </Text>
              
            </Flex>
            <Flex
            justifyContent={"center"}
              direction={"column"}
              alignItems={"center"}
            >
              <Button
                onClick={() => resetAnswers()}
                style={{ zIndex: 99, cursor: "pointer" }}
                width={"300px"}
                fontSize={"20px"}
                padding={"10px 20px"}
                backgroundColor={"#05C168"}
                color={"white"}
                border={"none"}
                borderRadius={"5px"}
                gap={"small"}
                boxShadow={"0 4px 8px rgba(0,0,0,0.2)"}
              >
                <FaArrowsTurnRight /> Return to your Score
              </Button>
            </Flex>
          </Grid>
          <Grid
            height={"15vh"}
            width={"100vw"}
            backgroundColor={"white"}
            justifyContent={"center"}
          >
            <Grid
              width={{ base: "100vw", medium: "30vw", large: "40vw", xl: "40vw", xxl: "500px"}}
              templateColumns={"5fr 5fr"}
              paddingLeft={"15px"}
            >
              <TxtButton
                setter={() => handleBackChange(true)}
                txt={"Back"}
                type={"bottom"}
              />
              <TxtButton
                setter={() => handleBackChange(true)}
                txt={"Next"}
                type={"Placeholder"}
              />
            </Grid>
          </Grid>
        </Flex>
      )}
    </>
  );
}
