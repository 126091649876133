import * as React from "react";
import { View } from "@react-pdf/renderer";
import { Branding } from "../../../features/branding/types/branding";

export const PageLayout = ({
  reportStyles,
  left,
  right,
}: {
  reportStyles: Branding;
  left: React.ReactNode;
  right: React.ReactNode;
}) => (
  <View style={{ fontFamily: "Rubik", flexDirection: "row" }}>
    <View
      style={{ width: "70%", height: "100vh", padding: "20px 0px 5px 20px" }}
    >
      {left}
    </View>
    <View
      style={{
        width: "30%",
        backgroundColor: reportStyles.primaryColor,
        height: "100vh",
        color: reportStyles.fontColor,
        padding: "20px 20px 5px 20px",
        justifyContent: "space-between",
      }}
    >
      {right}
    </View>
  </View>
);
