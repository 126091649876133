// App.jsx
import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import { DataProvider } from "./components/CTA/DataContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "@aws-amplify/ui-react/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import ReconRoutes from "./routes/ReconRoutes";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorFallback from "./components/ErrorFallback";
import { Flex, Loader } from "@aws-amplify/ui-react";

Amplify.configure(awsExports);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      throwOnError: (error, key) => {
        toast.error(error.message, { toastId: key.queryHash[0] });
        return false;
      },
    },
  },
});

// Routing
const router = createBrowserRouter([
  {
    path: "/*",
    element: <ReconRoutes />,
  },
]);

function App() {
  return (
    <DataProvider>
      <QueryClientProvider client={queryClient}>
        <Suspense
          fallback={
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Loader />
            </Flex>
          }
        >
          <ErrorBoundary fallback={(error) => <ErrorFallback error={error} />}>
            <RouterProvider router={router} />
            <ToastContainer position="top-center" />
            <ReactQueryDevtools buttonPosition="bottom-left" />
          </ErrorBoundary>
        </Suspense>
      </QueryClientProvider>
    </DataProvider>
  );
}

export default App;
