import { post } from "../../../services/aws-amplify";

type Input = {
  email: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  companySite: string;
  msp?: string;
};

export async function mailchimpSubscribe(input: Input) {
  await post("MailchimpAPI", "/subscribe", { body: input }).catch(
    (error: any) => {
      console.log("Error subscribing to Mailchimp", error);
      throw error;
    }
  );
}
