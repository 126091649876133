import React from "react";
import { Button } from "@aws-amplify/ui-react";
import "./component.css"

export default function TxtButton({ setter, txt, type}: any) {
  const baseStyle = {
    cursor: "pointer",
    color: "#144EE3",
    backgroundColor: "white",
    border: "2px solid white",
    borderRadius: "8px",
    boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
    width: "100%",
    maxWidth: "450px",
    fontSize: "20px",
  };

  const style =
    type === "bottom"
      ? {
          cursor: "pointer",
          color: "white",
          backgroundColor: "#144EE3",
          border: "1px solid white",
          borderRadius: "8px",
          boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
          padding: "10px 20px",
          margin: "30px",
          maxWidth: "200px",
          maxHeight: "50px",
        }
      : type === "load"
      ? {
          cursor: "pointer",
          color: "white",
          backgroundColor: "lightgray",
          border: "1px solid white",
          borderRadius: "8px",
          boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
          padding: "10px 20px",
          margin: "30px",
          maxWidth: "300px",
          maxHeight: "50px",
        }
      : type === "Placeholder"
      ? {
          color: "white",
          backgroundColor: "white",
          border: "1px solid white",
          cursor: "pointer",
        }
      : baseStyle;

  const load = type === "load" ? true : type === "Placeholder" ? true : false;

  return (
    <Button className="button" onClick={() => setter(true)} style={style} minWidth={{ base: "150px", large: "200px" }} isDisabled={load}>
      {txt}
    </Button>
  );
}
