import { getEnvironment } from "../../services/getEnvironment";
import { invokeLambda } from "../util/invokeFunction";
import { CyberScoreCombined } from "./getCyberScoreCombined";

export type KeyTakeaways = {
  ["key-takeaway"]: string;
  threats:
    | {
        name: string;
        detail: string;
      }[]
    | undefined;
};

export async function genReconKeyTakeaways(cyberScore: CyberScoreCombined) {
  const env = await getEnvironment();

  const response = await invokeLambda(
    `TextGenApiHandler-${env === "main" ? "prod" : "dev"}`,
    {
      genType: "recon-key-takeaways",
      body: cyberScore,
    }
  ).catch((err) => {
    console.error(err);
    throw err;
  });
  return JSON.parse(response.body) as KeyTakeaways;
}
