import { Button, Flex, Heading, View } from "@aws-amplify/ui-react";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { useBranding } from "../features/branding/hooks";

type Props = {
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  header?: React.ReactNode;
  hasCloseButton?: boolean;
} & React.DetailedHTMLProps<
  React.DialogHTMLAttributes<HTMLDialogElement>,
  HTMLDialogElement
>;

export const Modal = React.forwardRef<HTMLDialogElement, Props>(
  ({ children, size, header, hasCloseButton }, ref) => {
    const width =
      size === "small" ? "600px" : size === "medium" ? "800px" : "1000px";

    const { branding } = useBranding();

    return (
      <dialog
        ref={ref}
        className="modal"
        style={{ maxWidth: width, padding: 0 }}
      >
        {ref ? (
          <Button
            hidden={!hasCloseButton}
            borderRadius={"medium"}
            position={"absolute"}
            variation="destructive"
            padding={"xs"}
            top={"xs"}
            right={"xs"}
            style={{ aspectRatio: 1 }}
            onClick={() => {
              (ref as React.RefObject<HTMLDialogElement>).current?.close();
            }}
          >
            <FaTimes />
          </Button>
        ) : null}
        {header ? (
          <Flex
            padding={"large xxxl large large"}
            backgroundColor={branding.primaryColor}
            alignItems={"center"}
            
          >
            <Heading color={"white"} level={4}>{header}</Heading>
          </Flex>
        ) : null}
        <View padding={"xxl xl"}>{children}</View>
      </dialog>
    );
  }
);
