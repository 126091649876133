import xMark from "../../../../assets/images/pdf/cs-red-x.png";
import checkMark from "../../../../assets/images/pdf/cs-green-check.png";
import notAvail from "../../../../assets/images/pdf/cs-not-available.png";

export const getScoreImage = (score: -1 | 0 | 1) => {
  switch (score) {
    case -1:
      return xMark;
    case 0:
      return notAvail;
    case 1:
      return checkMark;
    default:
      return xMark;
  }
};
