import React from "react";
import { Text } from "@aws-amplify/ui-react";
import "./component.css";

export default function TxtResponse({ txt }: any) {
  return (
    <Text
      color={"#144EE3"}
      backgroundColor={"white"}
      border={"2px solid white"}
      borderRadius={"8px"}
      boxShadow={"2px 10px 12px 2px rgba(0, 0, 0, .15)"}
      className="response"
      width="40vw"
      minWidth="300px"
      maxWidth="450px"
      textAlign={"center"}
    >
      {txt}
    </Text>
  );
}
