import React, { useState } from "react";
// import React, { useState } from "react";
import P10 from "./P10";
import { Flex, Grid, Text, TextField, Button } from "@aws-amplify/ui-react";
import TxtButton from "../ui_components/TxtButton";
import cs1 from "../assets/cs1.png";
import usePDFDownloader from "../../../hooks/usePDFDownloader";
import { useData } from "../DataContext";
import P12 from "./P12";
import "../ui_components/component.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function P11() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState("");
  const [value, setValue] = useState("");

  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);

  const onChange = (event: any) => {
    setValue(event.currentTarget.value);
  };

  const { handleAnswer, domain } = useData();

  const { savePDFScore, isLoading: isDownloaderLoading } = usePDFDownloader({
    domain,
  });

  function submitClick(answer: any) {
    // checks for Does not start with spaces. Contains an @ symbol Does not include spaces within. Contains a period in the domain part.
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(answer)) {
      savePDFScore();
      handleAnswer("email", answer);
      handleNextChange(true);
    } else {
      toast.error("Please enter a valid email address.");
    }
  }

  return (
    <>
      {back ? (
        <P10 />
      ) : (
        <>
          {next ? (
            <P12 />
          ) : (
            <>
              <ToastContainer
                position="top-center"
                style={{ width: "100vw", margin: "0px", maxWidth: "800px" }}
              />
              <Flex
                direction={"column"}
                gap="0"
                alignItems={"center"}
                className="page"
                backgroundColor={"#144EE3"}
                height={{ base: "100vh", large: "90vh" }}
                width={"100vw"}
                maxWidth={{ base: "100vw", large: "600px" }}
                maxHeight={{ large: "900px" }}
              >
                <Grid
                  height={{ base: "85vh", medium: "75vh", large: "75vh" }}
                  width={"90%"}
                  templateRows={{
                    base: "3fr 1fr 9fr",
                    medium: "2fr 1fr 6fr",
                    large: "2fr 1fr 5fr",
                  }}
                  paddingTop={{ base: "20%", medium: "10%", large: "10%" }}
                  templateColumns={"auto"}
                >
                  <img
                    src={cs1}
                    height="100px"
                    width="100px"
                    alt="Cyber Score Image"
                    style={{
                      borderRadius: "50%",
                      boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
                      gridRow: "1",
                      justifySelf: "center",
                    }}
                  />
                  <Flex justifyContent={"center"} direction={"column"}>
                    <Text
                      color={"white"}
                      textAlign={"center"}
                      // fontSize={"large"}
                      fontWeight={"bold"}
                      fontSize={"22px"}
                    >
                      Grab your keys, your Cyber Roadmap is ready!
                    </Text>
                    <Text
                      color={"white"}
                      textAlign={"center"}
                      fontSize={"22px"}
                      fontWeight={"bold"}
                    >
                      Enter your email to get your Roadmap.
                    </Text>
                  </Flex>
                  <Flex
                    direction={"column"}
                    gap="20px"
                    paddingTop={"2%"}
                    alignItems={"center"}
                  >
                    <Flex
                      direction={"column"}
                      backgroundColor="rgba(255, 255, 255, 0.8)"
                      borderRadius={"8px"}
                      border={"1px solid white"}
                      padding={"20px 10px"}
                      boxShadow={"10px 5px 10px #144EE3"}
                      width={"100%"}
                      minWidth={"250px"}
                      maxWidth={"450px"}
                    >
                      <TextField
                        value={value}
                        onChange={onChange}
                        type="email"
                        isRequired={true}
                        label={
                          <Text
                            color={"#144EE3"}
                            // fontSize={"medium"}
                            className="headers"
                            fontWeight={"bold"}
                          >
                            Email Address
                          </Text>
                        }
                      />
                      {!isDownloaderLoading && value && (
                        <Button
                          style={{
                            color: "#144EE3",
                            backgroundColor: "white",
                            border: "1px solid white",
                            borderRadius: "8px",
                            boxShadow: "2px 10px 12px 2px rgba(0, 0, 0, .15)",
                            padding: "10px 20px",
                            // maxWidth: "200px",
                            maxHeight: "50px",
                          }}
                          onClick={() => submitClick(value)}
                        >
                          Download PDF
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </Grid>
                <Grid
                  height={"15vh"}
                  width={"100vw"}
                  backgroundColor={"white"}
                  justifyContent={"center"}
                >
                  <Grid
                    width={{
                      base: "100vw",
                      medium: "30vw",
                      large: "40vw",
                      xl: "40vw",
                      xxl: "500px",
                    }}
                    templateColumns={"5fr 5fr"}
                    paddingLeft={"15px"}
                  >
                    <TxtButton
                      setter={() => handleBackChange(true)}
                      txt={"Back"}
                      type={"bottom"}
                    />
                    {/* <TxtButton
                      setter={() => handleNextChange(true)}
                      txt={"Next"}
                      type={"bottom"}
                    /> */}
                  </Grid>
                </Grid>
              </Flex>
            </>
          )}
        </>
      )}
    </>
  );
}
