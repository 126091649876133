// DataContext.js
import React, { createContext, useContext, useState } from 'react';
import { saveUserInput } from '../../hooks/services/saveUserInput';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({
    dateAdded: "",
    domain: "",
    sessionId: "",
    employeesCount: "",
    annualRevenue: "",
    industry: "",
    mfaBankAccount: false,
    mfaEmailAccounts: false,
    conductingBackups: false,
    updatingDevices: false,
    itHandler: "",
    itBudgetImportance: "",
    email: "",
    teaser: "",
    objectives: []
  });

  const [roadmap, setRoadmap] = useState(false);

  const [cyberScoreContext, setCyberScoreContext] = useState({});

  //hard-coded questions & answers
  let questions = {
    employeesCount: {
      question: "How many employees do you have?",
      answer: data.employeesCount,
      options: ["< 10", "11 - 20", "21 - 50", "> 50"],
    },
    annualRevenue: {
      question: "What is your organization's annual revenue?",
      answer: data.annualRevenue,
      options: ["< $500k", "$500k - $1M", "$1M - $5M", "> $5M"],
    },
    industry: {
      question: "What industry is your organization in?",
      answer: data.industry,
    },
    mfaBankAccount: {
      question: "Does your organization use multi-factor authentication for bank accounts?",
      answer: data.mfaBankAccount,
    },
    mfaEmailAccounts: {
      question: "Does your organization use multi-factor authentication for email accounts?",
      answer: data.mfaEmailAccounts,
    },
    conductingBackups: {
      question: "Is your organization conducting backups?",
      answer: data.conductingBackups,
    },
    updatingDevices: {
      question: "Is your organization updating devices?",
      answer: data.updatingDevices,
    },
    itHandler: {
      question: "Who is handling your IT?",
      answer: data.itHandler,
      options: ["We figure it out as we go", "One of our employee's cousins", "We have a trained IT person on staff", "We outsource to an IT company"],
    },
    itBudgetImportance: {
      question: "How important is IT in your monthly budget?",
      answer: data.itBudgetImportance,
      options: ["$50/user: I'm trying to pinch pennies", "$100/user: Squared away with the basics", "$150/user: On the ball with client data protection", "$200/user: I need IT to never be a headache"],
    }
  };

  // peter responses
//   const peterResponses = [
//     {
//     response1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"},
//     {
//     response2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"},
//     {
//     response3: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"}
// ]
  

  const handleAnswer = (key, value) => {
    setData(prevData => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleRoadmap = (bool) => {
    setRoadmap(bool);
  }

  // // lambda function can go here
  // saveUserInput(data);

  return (
    <DataContext.Provider value={{ 
      data, 
      questions, 
      handleAnswer, 
      domain: data.domain,
      cyberScoreContext,
      setCyberScoreContext,
      roadmap,
      handleRoadmap
    }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
