import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export function useViewAccess() {
  const [searchParams, setSearchParams] = useSearchParams();

  const viewAccess = searchParams.get("view-access");

  const unlockDetails = useCallback(() => {
    setSearchParams((prev) => {
      prev.append("view-access", "unlocked");
      return prev;
    });
  }, [searchParams]);

  return {
    detailsHidden: viewAccess !== "unlocked",
    unlockDetails,
  };
}
