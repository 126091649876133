import { get as _get, post as _post, put, del } from "aws-amplify/api";

type Options = {
  headers?: Record<string, string>;
  body?: any;
  queryParams?: any;
};

export async function getRequest(path: string, options?: any) {
  return await _get({ apiName: "SpaRestAPI", path, options }).response;
}
export function post(apiName: string, path: string, options?: any) {
  return _post({ apiName, path, options }).response;
}
export function putRequest(path: string, options?: any) {
  return put({ apiName: "TheCyberScoreRestAPI", path, options }).response;
}
export function delRequest(path: string, options?: any) {
  return del({ apiName: "TheCyberScoreRestAPI", path, options }).response;
}

export function get(apiName: string, path: string, options?: Options) {
  return _get({ apiName, path, options });
}
