import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { getImageToBase64 } from "./services/getImageToBase64";

export function useImageToBase64Query(
  url: string | undefined,
  fallbackUrl?: string,
  options?: UseQueryOptions<string>
) {
  const { data: base64Data, ...query } = useQuery({
    queryKey: ["image", "base-64", url],
    queryFn: async () => {
      try {
        const data = await getImageToBase64(url as string);
        // if (data.includes("data:image/svg+xml")) {
        //   const uri = await svgToDataURI(data, 200, 200);
        //   return uri;
        // }

        return data;
      } catch (err) {
        if (fallbackUrl) {
          const data = await getImageToBase64(fallbackUrl as string);
          return data;
        } else throw err;
      }
    },
    enabled: !!url,
    ...options,
  });

  return {
    base64Data,
    ...query,
  };
}
