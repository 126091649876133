import { useQuery } from "@tanstack/react-query";
import { getImageToBase64 } from "./services/getImageToBase64";

export function useFavicon(domain: string | undefined | null) {
  // const rootDomain = useRootDomain(company);

  const fallback = "/images/graph-icon.png";

  const query = useQuery({
    queryKey: ["favicon", domain],
    queryFn: async () => {
      if (!domain) {
        return fallback;
      } else {
        let faviconUrl = `https://favicon.twenty.com/${domain}`;

        const isValid = await isValidImageURL(faviconUrl);
        if (isValid) {
          const faviconData = await getImageToBase64(faviconUrl);
          return faviconData;
        } else {
          return fallback;
        }
      }
    },
  });

  return { ...query };
}

const isValidImageURL = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};
