import React, { useMemo } from "react";
import { CyberScoreCombined } from "../../../hooks/services/getCyberScoreCombined";
import { Flex, Image } from "@aws-amplify/ui-react";

type Props = {
  cyberScore: CyberScoreCombined;
};

export default function ProfilePictures({ cyberScore }: Props) {
  // const { profilePics } = useLinkedinProfilePics(
  //   cyberScore?.Employees.map((e) => e.SourcePage).filter((s) => s !== null),
  //   {
  //     n: 4,
  //   }
  // );

  const profilePics = useMemo(() => {
    return [cyberScore.Employee1, cyberScore.Employee2].filter(
      (e) => !!e
    ) as string[];
  }, [cyberScore]);

  return (
    <Flex alignItems={"center"}>
      {profilePics.map((pfp, i) => (
        <Image
          // borderRadius={"75px"}
          alt={`Linkedin Profile Picture ${i}`}
          src={pfp}
          width={"175px"}
          objectFit={"cover"}
          height={"100%"}
          maxHeight={"175px"}
          marginLeft={i === 0 ? 0 : "-60px"}
          borderRadius="100%"
        ></Image>
      ))}
    </Flex>
  );
}
