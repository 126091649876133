import parser from "tld-extract";

export function extractDomainFromURL(url: string): string {
  // removeSchemeAndSubdomain(url);
  let urlText = url;
  try {
    // Sanitize and extract domain using tld-extract or fallback

    if (!urlText.startsWith("http://") && !urlText.startsWith("https://")) {
      urlText = "https://" + urlText;
    }

    const tldResult = parser(urlText);
    const extractedDomain = tldResult.domain;
    // tldResult.domain && tldResult.domain !== urlText ? tldResult.domain : urlText;

    // console.log("tldResult", tldResult);

    const domain = extractedDomain;
    // .replace(/^https?:\/\//, "")
    // .replace(/[^a-zA-Z0-9.-]/g, "");
    return domain;
  } catch (err) {
    if (urlText !== null) {
      const domain = urlText
        .replace(/^https?:\/\//, "")
        .replace(/[^a-zA-Z0-9.-]/g, "");
      return domain;
    }
    throw new Error("Invalid URL");
  }
}

// function removeSchemeAndSubdomain(url: string): string {
//   // Remove the scheme (http, https, etc.)
//   let modifiedUrl = url.replace(/(^\w+:|^)\/\//, "");

//   // Remove the subdomain if it exists
//   const parts = modifiedUrl.split(".");
//   if (parts.length > 2) {
//     // If the first part is a known subdomain, remove it
//     if (
//       ["www", "m", "dev", "test", "staging", "app", "blog", ""].includes(
//         parts[0]
//       )
//     ) {
//       parts.shift();
//     }
//   }

//   // Join the remaining parts back into a URL
//   return parts.join(".");
// }
