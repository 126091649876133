import React, {useState} from "react";
import PeterResponse from "../PeterResponse";
import P4 from "./P4";
import P6 from "./P6";

export default function P5() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState(false);

  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);

  return (
    <>
      {back ? (
        <P4 />
      ) : (
        <>
          {next ? (
            <P6 />
          ) : (
            <PeterResponse
              onNextChange={handleNextChange}
              onBackChange={handleBackChange}
              Response={"P1"}
            />
          )}
        </>
      )}
    </>
  );
}
