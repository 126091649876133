import { get } from "../../../hooks/util";
import { Branding } from "../types";

export async function getBranding(id: string): Promise<Branding> {
  try {
    const response = await get("BrandingAPI", `/brandings/object/${id}`)
      .response;
    const object = await response.body.json();
    return object as Branding;
  } catch (error) {
    throw error;
  }
}
