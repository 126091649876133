import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { CyberScoreCombined } from "../../../hooks/services/getCyberScoreCombined";

export const DarkWebBreaches = ({
  cyberScore,
}: {
  cyberScore: CyberScoreCombined;
}) => (
  <View style={{ gap: "5px" }}>
    <Text
      style={{
        fontWeight: "black",
        fontSize: "64px",
        color: "#FF343A",
        lineHeight: ".9"
      }}
    >
      {cyberScore.BreachList.length}
    </Text>
    <Text style={{ fontSize: "18px", lineHeight: ".8" }}>Dark Web</Text>
    <Text
      style={{
        fontWeight: "bold",
        fontSize: "18px",
        color: "#FF343A",
      }}
    >
      Breaches
    </Text>
  </View>
);
