import React, {useState} from "react";
import Intro from "../Intro";
import P5 from "./P5";
import P7 from "./P7";


export default function P6() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState(false);

  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);

  return (
    <>
      {back ? (
        <P5 />
      ) : (
        <>
          {next ? (
            <P7 />
          ) : (
            <Intro
            onNextChange={handleNextChange}
            onBackChange={handleBackChange}
            Response={"I2"}
            />
          )}
        </>
      )}
    </>
  );
}