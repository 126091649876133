import { get } from "../../../hooks/util";

export async function getIP(): Promise<string> {
  try {
    const response = await get("PortAPI", `/getIP`).response;
    const ip = await response.body.text();

    return ip;
  } catch (error) {
    throw error;
  }
}
