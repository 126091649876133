import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Branding } from "../../../features/branding/types/branding";

export const TitledCommentary = ({
  header,
  body,
  reportStyles,
}: {
  header: string;
  body: string;
  reportStyles: Branding;
}) => (
  <View style={{ gap: "2px", fontSize: "10px" }}>
    <Text style={{ color: reportStyles.accentColor, fontWeight: "bold" }}>
      {header}
    </Text>
    <Text>{body}</Text>
  </View>
);
