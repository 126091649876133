import { getEnvironment } from "../../services/getEnvironment";
import { invokeLambda } from "../util/invokeFunction";

export async function getOpenAiResponse(questions: any, responseType: any) {
  // Determine the lambda function name based on responseType
  const env = await getEnvironment();

  const lambdaFunctionName =
    responseType === "Teaser"
      ? `generateTeaserResponse-${env}`
      : `generateOpenAiResponse-${env}`;

  const openAiResponse = await invokeLambda(lambdaFunctionName, {
    body: JSON.stringify(questions),
  })
    .then((result) => {
      const openAiResponse = result.body;
      if (openAiResponse === undefined) {
        throw new Error(`Response is undefined.`);
      }
      return openAiResponse;
    })
    .catch(() => {
      // console.error(error);
      throw new Error("Error generating Roadmap analysis.");
    });
  return openAiResponse;
}
