import React, { useState } from "react";
import P8 from "./P8";
import P10 from "./P10";
import Questions from "../Questions";
import { useData } from '../DataContext';

export default function P9() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState(false);
  
  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);

  const { handleAnswer } = useData();

  const onAnswerAndUpdateNext = (answer: any) => {
    handleAnswer('itBudgetImportance', answer);
    setNext(true);
  };

  return (
    <>
      {back ? (
        <P8 />
      ) : (
        <>
          {next ? (
            <P10 />
          ) : (
            <Questions
              onNextChange={handleNextChange}
              onAnswer={onAnswerAndUpdateNext}
              onBackChange={handleBackChange}
              Response={"Q4"}
            />
          )}
        </>
      )}
    </>
  );
}
