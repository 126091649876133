import React, { useState } from "react";
import P7 from "./P7";
import P9 from "./P9";
import Questions from "../Questions";
import { useData } from '../DataContext';

export default function P8() {
  const [next, setNext] = useState(false);
  const [back, setBack] = useState(false);
  
  const handleNextChange = (value: any) => setNext(value);
  const handleBackChange = (value: any) => setBack(value);

  const { handleAnswer } = useData();

  const onAnswerAndUpdateNext = (answer: any) => {
    handleAnswer('itHandler', answer);
    setNext(true);
  };

  return (
    <>
      {back ? (
        <P7 />
      ) : (
        <>
          {next ? (
            <P9 />
          ) : (
            <Questions
              onNextChange={handleNextChange}
              onAnswer={onAnswerAndUpdateNext}
              onBackChange={handleBackChange}
              Response={"Q3"}
            />
          )}
        </>
      )}
    </>
  );
}
