import { useQuery } from "@tanstack/react-query";
import { ScoreClaim } from "../types/score-claim";
import { get } from "../../../services/aws-amplify";

type Options = {
  domain: string | undefined;
};

export function useScoreClaimListQuery({ domain }: Options) {
  const query = useQuery({
    queryKey: ["score-claim", "list", domain],
    queryFn: async () => {
      const response = await get("ScoreClaimAPI", `/score-claim/${domain}`)
        .response;
      return response.body.json() as any as ScoreClaim[];
    },
    enabled: !!domain,
  });

  const approvedClaim = query.data?.find(
    (claim) => claim.status === "approved"
  );

  return { ...query, approvedClaim };
}
