import { getEnvironment } from "../../services/getEnvironment";
import { invokeLambda } from "../util/invokeFunction";
import { CyberScoreCombined } from "./getCyberScoreCombined";

export type ActionPlan = {
  actions:
    | {
        title: string;
        detail: string;
      }[]
    | undefined;
};

export async function genReconActionPlan(cyberScore: CyberScoreCombined) {
  const env = await getEnvironment();
  const response = await invokeLambda(
    `TextGenApiHandler-${env === "main" ? "prod" : "dev"}`,
    {
      genType: "recon-action-plan",
      body: cyberScore,
    }
  ).catch((err) => {
    console.error(err);
    throw err;
  });
  try {
    return JSON.parse(response.body) as ActionPlan;
  } catch (err) {
    console.error("Failed to parse: ", err);
    throw new Error("Failed get Recon Action Plan");
  }
}
